import { Component, inject, Input } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { UserBackupService } from "src/app/shared/service/user-backup.service";

@Component({
  selector: "app-membership-customer",
  standalone: true,
  imports: [FormsModule],
  templateUrl: "./membership-customer.component.html",
  styleUrl: "./membership-customer.component.scss",
})
export class MembershipCustomerComponent {
  @Input() membershipId: string;
  @Input({ required: true }) userId: string;
  loader:boolean=false
  monthValue: number | null = null;
  _UserBackupService = inject(UserBackupService);
     toastr=inject(ToastrService)  
  addMembership() {
    this.loader=true
    this._UserBackupService.addMembership(this.userId).subscribe({
      next: (data) => {
        console.log(data);
        this.membershipId = data.data.membership;
        this.loader=false
        this.toastr.success('Hurray', 'Membership added');
      },
      error: (err) => console.log(err),
    });
  }
  extendMembership(month: number) {
    this.loader=true
    this._UserBackupService.extendMembership(this.userId, month).subscribe({
      next: (data) => {
        console.log(data);
        this.loader=false
        this.monthValue=null
        this.toastr.success('Hurray', 'Membership extended');
      },
      error: (err) => console.log(err),
    });
  }
}
