<div class="container-fluid">
    <div class="card">
        <div class="card-header">
           <div class="">
            <h5 class="float-start">Search User by mobile number</h5>
            <div class="float-end">
                <button type="button" class="btn btn-outline-secondary btn-sm" routerLink="'/users/create-user'">
                    <i class="bi bi-person-plus-fill"></i> Create User</button>
            </div>
           </div>
        </div>
        <div class="card-body">
            
            <div class="input-group mb-3">
                <span class="input-group-text no-right-border" id="basic-addon1">
                    <i class="bi bi-search"></i>
                  </span>
                <input type="text" class="form-control no-left-border" placeholder=" Search User  by mobile number" aria-label="Search User  by mobile number" aria-describedby="basic-addon2">
                <span class=""><button type="button" class="btn btn-primary" (click)="search()">
                    Search </button></span>
            </div>
        </div>
    </div>
</div>