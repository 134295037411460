import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-user',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule,CommonModule],
  templateUrl: './search-user.component.html',
  styleUrl: './search-user.component.scss'
})
export class SearchUserComponent {
 
  constructor( private router: Router){

  }

  ngOnInit(){

  }

  search() {
    this.router.navigate(['/users/list-user'])
    console.log('Searching user with mobile number', );
  }
}
