import { Routes } from "@angular/router";
import { authGuard, authRoleGuard } from "src/app/components/auth/auth.guard";
import { EditAddressComponent } from "src/app/main/customer-new/edit-address/edit-address.component";
import { EditMobileNumberComponent } from "src/app/main/customer-new/edit-mobile-number/edit-mobile-number.component";

export const content: Routes = [
  {
    path: "dashboard",
    loadChildren: () =>
      import("../../components/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
      canActivate:[authGuard]
  },
  {
    path: "order-assignments",
    loadChildren: () =>
      import("../../main/orders-assignments/orders-assignments.module").then(
        (m) => m.OrdersAssignmentsModule
      ),
    data: {
      title: "Order Assignments",
      breadcrumb: "Order Assignments",
    },
    canActivate:[authGuard]
  },
  {
    path: "products",
    loadChildren: () =>
      import("../../main/products/products.module").then(
        (m) => m.ProductsModule
      ),
    data: {
      title: "Products",
      breadcrumb: "Products",
    },
    canActivate:[authGuard]
  },
  {
    path: "membership-packages",
    loadChildren: () =>
      import("../../main/membership-packages/membership-packages.module").then(
        (m) => m.MembershipPackagesModule
      ),
    data: {
      title: "Membership Packages",
      breadcrumb: "Membership Packages",
    },
    canActivate:[authGuard]
  },
  {
    path: "app-constants",
    loadChildren: () =>
      import("../../main/app-constants/app-constants.module").then(
        (m) => m.AppConstantsModule
      ),
    data: {
      title: "App Constants",
      breadcrumb: "App Constants",
    },
    canActivate:[authGuard]
  },
  {
    path: "delivery-area",
    loadChildren: () =>
      import("../../main/delivery-area/delivery-area.module").then(
        (m) => m.DeliveryAreaModule
      ),
    data: {
      title: "Delivery Area",
      breadcrumb: "Delivery Area",
    },
    canActivate:[authGuard]
  },
  {
    path: "coupons",
    loadChildren: () =>
      import("../../main/coupons/coupons.module").then((m) => m.CouponsModule),
    data: {
      title: "Coupons",
      breadcrumb: "Coupons",
    },
    canActivate:[authGuard]
  },
  {
    path: "customer-mobile",
    loadComponent: () =>
      import(
        "../../main/customer-new/search-mobile-customer/search-mobile-customer.component"
      ).then((m) => m.SearchMobileCustomerComponent),
    data: {
      title: "Search Customer By Mobile",
      breadcrumb: "Search Customer By Mobile",
    },
  },
  {
    path: "customer-mobile/:id",
    loadComponent: () =>
      import(
        "../../main/customer-new/search-mobile-customer/search-mobile-customer.component"
      ).then((m) => m.SearchMobileCustomerComponent),
    data: {
      title: "Search Customer By Mobile",
      breadcrumb: "Search Customer By Mobile",
    },
  },
  {
    path: "customers",
    loadChildren: () =>
      import("../../main/customers/customers.module").then(
        (m) => m.CustomersModule
      ),
    data: {
      title: "Customers",
      breadcrumb: "Customers",
    },
    canActivate:[authGuard]
  },
  {
    path: "tax-rules",
    loadChildren: () =>
      import("../../main/tax-rules/tax-rules.module").then(
        (m) => m.TaxRulesModule
      ),
    data: {
      title: "Tax Rules",
      breadcrumb: "Tax Rules",
    },
    canActivate:[authGuard]
  },
  {
    path: "water-cans",
    loadChildren: () =>
      import("../../main/watercans/watercans.module").then(
        (m) => m.WatercansModule
      ),
    data: {
      title: "Water cans",
      breadcrumb: "Water cans",
    },
    canActivate:[authGuard]
  },
  {
    path: "payments",
    loadChildren: () =>
      import("../../main/payments/payments.module").then(
        (m) => m.PaymentsModule
      ),
    data: {
      title: "Payments",
      breadcrumb: "Payments",
    },
    canActivate:[authGuard]
  },
  {
    path: "orders",
    loadChildren: () =>
      import("../../main/orders/orders.module").then((m) => m.OrdersModule),
    data: {
      title: "Orders",
      breadcrumb: "Orders",
    },
    canActivate:[authGuard]
  },

  {
    path: "admin-staff",
    loadChildren: () =>
      import("../../main/admin-staff/admin-staff.module").then(
        (m) => m.AdminStaffModule
      ),
    data: {
      title: "Admin Staff",
      breadcrumb: "Admin Staff",
    },
    canActivate:[authRoleGuard]
  },
  {
    path: "vehicles",
    loadChildren: () =>
      import("../../main/vehicle/vehicle.module").then((m) => m.VehicleModule),
    data: {
      title: "Vehicles",
      breadcrumb: "Vehicles",
    },
    canActivate:[authGuard]
  },

  {
    path: "accessory",
    loadChildren: () =>
      import("../../main/accessory/accessory.module").then(
        (m) => m.AccessoryModule
      ),
    data: {
      title: "Accessory",
      breadcrumb: "Accessory",
    },
    canActivate:[authGuard]
  },
  {
    path: "delivery-staff",
    loadChildren: () =>
      import("../../main/delivery-staff/delivery-staff.module").then(
        (m) => m.DeliveryStaffModule
      ),
    data: {
      title: "Delivery Staff",
      breadcrumb: "Delivery Staff",
    },
    canActivate:[authGuard]
  },
  {
    path: "tracking-code",
    loadChildren: () =>
      import("../../main/tracking-code/tracking-code.module").then(
        (m) => m.TrackingCodeModule
      ),
    data: {
      title: "Tracking Code",
      breadcrumb: "Tracking Code",
    },
    canActivate:[authGuard]
  },
  {
    path: "edit-mobile-number/:id",
    component: EditMobileNumberComponent,
    data: {
      title: "Edit Mobile Number",
      breadcrumb: "Edit mobile number",
      back: "/customers/default/new",
    }
  },
  {
    path: "edit-customer-address/:id",
    component: EditAddressComponent,
    data: {
      title: "Edit Customer Address",
      breadcrumb: "Edit Customer Address",
      back: "/customers/default/new",
    },
    canActivate:[authGuard]
  },
  // {
  //   path: 'products',
  //   loadChildren: () => import('../../components/products/products.module').then(m => m.ProductsModule),
  //   data: {
  //     breadcrumb: "Products"
  //   }
  // },
  // {
  //   path: 'sales',
  //   loadChildren: () => import('../../components/sales/sales.module').then(m => m.SalesModule),
  //   data: {
  //     breadcrumb: "Sales"
  //   }
  // },
  // {
  //   path: 'coupons',
  //   loadChildren: () => import('../../components/coupons/coupons.module').then(m => m.CouponsModule),
  //   data: {
  //     breadcrumb: "Coupons"
  //   }
  // },
  // {
  //   path: 'pages',
  //   loadChildren: () => import('../../components/pages/pages.module').then(m => m.PagesModule),
  //   data: {
  //     breadcrumb: "Pages"
  //   }
  // },
  // {
  //   path: 'media',
  //   loadChildren: () => import('../../components/media/media.module').then(m => m.MediaModule),
  // },
  // {
  //   path: 'menus',
  //   loadChildren: () => import('../../components/menus/menus.module').then(m => m.MenusModule),
  //   data: {
  //     breadcrumb: "Menus"
  //   }
  //},
  {
    path: "inventories",
    loadChildren: () =>
      import("../../main/inventories/inventories-routing.module").then(
        (m) => m.InventoriesRoutingModule
      ),
    data: {
      breadcrumb: "Inventory",
    },
    canActivate:[authGuard]
  },
  {
    path: "users",
    loadChildren: () =>
      import("../../components/users/users.module").then((m) => m.UsersModule),
    data: {
      breadcrumb: "Users",
    },
    canActivate:[authGuard]
  },
  // {
  //   path: 'vendors',
  //   loadChildren: () => import('../../components/vendors/vendors.module').then(m => m.VendorsModule),
  //   data: {
  //     breadcrumb: "Vendors"
  //   }
  // },
  // {
  //   path: 'localization',
  //   loadChildren: () => import('../../components/localization/localization.module').then(m => m.LocalizationModule),
  //   data: {
  //     breadcrumb: "Localization"
  //   }
  // },
  // {
  //   path: 'reports',
  //   loadChildren: () => import('../../components/reports/reports.module').then(m => m.ReportsModule),
  // },
  // {
  //   path: 'settings',
  //   loadChildren: () => import('../../components/setting/setting.module').then(m => m.SettingModule),
  //   data: {
  //     breadcrumb: "Settings"
  //   }
  // },
  // {
  //   path: 'invoice',
  //   loadChildren: () => import('../../components/invoice/invoice.module').then(m => m.InvoiceModule),
  //   data: {
  //     breadcrumb: "Invoice"
  //   }
  // },
  {
    path: "past-orders-on-map",
    loadChildren: () =>
      import("../../main/past-orders-on-map/past-orders-on-map.module").then(
        (m) => m.PastOrdersOnMapModule
      ),
    data: {
      title: "Past Orders On Map",
      breadcrumb: "Past Orders On Map",
    },
    canActivate:[authGuard]
  },
  {
    path: "active-orders-on-map",
    loadChildren: () =>
      import("../../main/active-orders/active-orders.module").then(
        (m) => m.ActiveOrdersModule
      ),
    data: {
      title: "Active Orders On Map",
      breadcrumb: "Active Orders On Map",
    },
    canActivate:[authGuard]
  },
  {
    path: "whatsapp-message-templates",
    loadChildren: () =>
      import(
        "../../main/whatsapp-message-templates/whatsapp-message-templates.module"
      ).then((m) => m.WhatsappMessageTemplatesModule),
    data: {
      title: "Whatsapp Message Templates",
      breadcrumb: "Whatsapp Message Templates",
    },
    canActivate:[authGuard]
  },
  {
    path: "interested-users",
    loadChildren: () =>
      import("../../main/interested-users/interested-users.module").then(
        (m) => m.InterestedUsersModule
      ),
    data: {
      title: "Interested Users",
      breadcrumb: "Interested Users",
    },
    canActivate:[authGuard]
  },
];
