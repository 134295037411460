export class AppConstants {
    
    static appconfigUrl = './assets/configuration/webconfig.json';
    static deviceDeposit = 500;
    static apiAccessKey ='token';
    //delivery staff
    static signupDeliveryBoyUrl = 'api/admin/registerDeliveryBoy';
    static searchDeliveryBoyByCompanyNumUrl = 'api/admin/searchdeliveryboy/bycompanyphone';
    static searchDeliveryBoyByMobileUrl = 'api/admin/searchdeliveryboy/bymobile';
    static searchDeliveryBoyByNameUrl = 'api/admin/searchdeliveryboy/byname';
    static getAllDeliveryBoysUrl = 'api/admin/getAllDeliveryBoys';
    static deleteDeliveryBoyUrl = 'api/admin/deleteDeliveryBoy';
    static editDeliveryBoyUrl = 'api/admin/editDeliveryBoy';
    static getDeliveryBoyInfoUrl = 'api/admin/getDeliveryBoyInfo';
    static giveCashToDeliveryBoyUrl = 'api/admin/give/cash/to/delivery/boy';
    static receiveCashFromDeliveryBoyUrl = 'api/admin/receive/cash/from/delivery/boy';
    static displayTransactionHistoryOfDeliveryBoyUrl = 'api/admin/display/transaction/history/of/delivery/executive';

    //installation staff
    static signupInstallationBoyUrl = 'api/admin/registerInstallationBoy';
    static searchInstallationBoyByMobileUrl = 'api/admin/searchInstallationBoy/bymobile';
    static searchInstallationBoyByCompanyNumUrl = 'api/admin/searchInstallationBoy/bycompanyphone';
    static searchInstallationBoyByNameUrl = 'api/admin/searchInstallationBoy/byname';
    static getAllInstallationBoysUrl = 'api/admin/getAllInstallationBoys';
    static getInstallationBoyInfoUrl = 'api/admin/getInstallationBoyInfo';
    static editInstallationBoyUrl = 'api/admin/editInstallationBoy';
    static deleteInstallationBoyUrl = 'api/admin/deleteInstallationBoy';
    static giveCashToInstallationBoyUrl = 'api/admin/give/cash/to/installation/boy';
    static receiveCashFromInstallationBoyUrl = 'api/admin/receive/cash/from/installation/boy';
    static displayTransactionHistoryOfInstallationBoyUrl = 'api/admin/display/transaction/history/of/installation/executive';

   //administrative staff
    static signupAdminStaffUrl = 'api/admin/registerAdminStaffMember';
    static searchAdminStaffByEmailUrl = 'api/admin/searchAdminStaff/byemail';
    static searchAdminStaffByMobileUrl = 'api/admin/searchAdminStaff/bymobile';
    static searchAdminStaffByNameUrl = 'api/admin/searchAdminStaff/byname';
    static activateAdminStaffUrl = 'api/admin/activate/adminstaff';
    static deactivateAdminStaffUrl = 'api/admin/deactivate/adminstaff';
    //static getAllAdminStaffUrl = 'api/admin/getAllAdminStaffMembers';
    
    //products
    static addProductUrl = 'api/admin/addProduct'; //from admin-general
    static searchProductByBrandNameUrl = 'api/admin/searchProductByBrandName';
    static searchProductBySupplierNameUrl = 'api/admin/searchProductBySupplierName';
    static getAllProductsUrl = 'api/admin/getProducts'; //from admin-general
    static getProductInfoUrl = 'api/admin/getProduct'; //from admin-general
    static editProductUrl = 'api/admin/editProduct'; //from admin-general
    static deleteProductUrl = 'api/admin/deleteProduct'; //from delete-product
    static searchProductByAddressUrl = 'api/admin/searchProductByAddress';
    static attachTaxRuleToProductUrl = 'api/admin/attach/gst/to/product';
    static dettachGSTfromProductUrl = 'api/admin/detach/gst/from/product';
    
    //tax-rules
    static addTaxRuleUrl = 'api/admin/addTaxRule';
    static getAllTaxRules = 'api/admin/getAllTaxRules';
    static deleteTaxRuleUrl = 'api/admin/deleteTaxRule';
    static getTaxRuleInfoUrl = 'api/admin/getTaxRuleInfo';
    static editTaxRuleUrl = 'api/admin/editTaxRule';
    
    //accessories
    static addAccessoryUrl = 'api/admin/addAccessories';
    static getAccessoriesUrl = 'api/admin/getAccessories'; //from admin-user
    static deleteAccessoryUrl = 'api/admin/deleteAccessory';
    static getAccessoryInfoUrl = 'api/admin/getAccessory';
    static editAccessoryUrl = 'api/admin/editAccessory';
    static searchAccessoryByBrandNameUrl = 'api/admin/searchAccessoryByName';
    static attachTaxRuleToAccessoryUrl = 'api/admin/attach/gst/to/accessory';
    static dettachGSTfromAccessoryUrl = 'api/admin/detach/gst/from/accessory';

    //admin staff
    static addAdminStaffUrl = 'api/admin/add/adminstaff';
    static editAdminStaffUrl = 'api/admin/edit/admin/staff';
    static getAdminStaffInfoUrl = 'api/admin/get/adminstaff/info';
    static getAllAdminStaffUrl = 'api/admin/get/all/adminstaff';
    static deleteAdminStaffUrl = 'api/admin/delete/adminstaff';
    
    //customers
    static signupUserOnRequestUrl = 'api/admin/signupUserOnRequest';
    static searchUserByNameUrl = 'api/admin/searchUser/byname';
    static searchUserByMobileUrl = 'api/admin/search/user/by/mobile/number';
    static searchUserByEmailUrl = 'api/admin/searchUser/byemail';
    static getUserInfoUrl = 'api/admin/getUser';
    static getAllUsersUrl = 'api/admin/getAllUsers';
    static editUserByEmailUrl = 'api/admin/editUserByEmail';
    static editUserByNameUrl = 'api/admin/editUserByName';
    static editUserByMobileUrl = 'api/admin/editUserByMobile';
    static deleteCustomerUrl = 'api/admin/deleteUser';
    static getInterestedUserLocationsUrl = 'api/get/all/interesteduser/locations';
    static getInterestedUserDetailsUrl = 'api/get/interesteduser/details';

    
    //delivery areas
    static addDeliveryAreaUrl = 'api/admin/addDeliveryArea'; //available in lambda
    static getAllDeliveryAreasUrl = 'api/admin/get/all/deliveryareas'; //available in lambda
    static getAllDelAreasCustomer = 'api/admin/get/all/del/areas/user'; //for customer section , to be removed in production
    static editDeliveryAreaUrl = 'api/admin/editDeliveryArea'; //available in lambda
    static getDeliveryAreaInfoUrl = 'api/admin/getDelivery/area/Info'; //available in lambda
    static getAllProductsListUrl = 'api/admin/getlist/of/available/products'; //available in lambda
    static editProductForDelAreaUrl = 'api/admin/editProduct/attached/to/delArea'; //available in lambda
    static addProductToDelAreaUrl = 'api/admin/attachProduct/to/delivery/area'; //available in lambda
    static deleteProductFromDelAreaUrl = 'api/admin/delete/product/attached/to/delArea'; //available in lambda
    static addAccessoryToDelAreaUrl = 'api/admin/attachAccessory/to/deliveryarea'; //not done
    static addDeviceDepositUrl = 'api/admin/attachDeviceDeposit/to/deliveryarea'; //not done
    static detachDeviceFomUserUrl = 'api/admin/dettach/device/to/user';
    static saveDeliveryAreaTimingsUrl = 'api/admin/edit/delivery/area/timings';
    static deleteDelAreaDetailsUrl = 'api/admin/delete/delivery/area/details';
    static editLagNormalDeliveryAreaTimingsUrl = 'api/admin/edit/normal/lag/delivery/area/timings';
    static editLagBacklogDeliveryAreaTimingsUrl = 'api/admin/edit/backlog/lag/delivery/area/timings';
    
    //inventories
    static getAllInventoriesOnMapUrl = 'api/admin/showInventoriesOnMap';
    static getAllInventoriesUrl = 'api/admin/getAllInventories';
    static deleteInventoryUrl = 'api/admin/deleteInventory';
    static addInventoryUrl = 'api/admin/add/inventory'; //from admin-general
    static editInventoryUrl = 'api/admin/edit/inventory'; //from admin-general
    static getInventoryInfoUrl = 'api/admin/get/inventory/info'; //from admin-general
    static searchInventoryByAddressUrl = 'api/admin/searchInventoryByAddress';
    static generateInstallationSlotsUrl = 'api/admin/addInstallationSlots/To/DeliveryArea';
    static editInstallationDateUrl = 'api/admin/addInstallationSlots/To/DeliveryArea';
    static editInstallationSlotOfParticularDateUrl = 'api/admin/editinstallationSlot/ofparticulardate/deliveryArea';
    static deleteDateFromInstallationSlotUrl = 'api/admin/deleteinstallationdate/delArea';
    static getInstallationDataUrl = 'api/admin/get/installation/slot/data';
    static changeInstallationConstantsUrl = 'api/admin/change/installation/slots/constants';
    static getInstallationConstantsUrl = 'api/admin/get/installation/constants/delArea';
    static assignInstallationBoyToOrderUrl = 'api/installation/assign/installationboy/to/order';
    static removeInstallationBoyFromOrderUrl = 'api/installation/remove/installationboy/assigned/to/order';
    static getInstallationOrdersWithRespectToDateUrl = 'api/admin/getInstallationOrders/for/date';
    static getInstallationOrdersWithRespectToInstallationBoyIdAndDateUrl = 'api/admin/getInstallationOrders/with/respect/to/installationboy';
    
    //inventories
    static getAllWatersuppliersUrl = 'api/admin/get/all/watersuppliers';
    static addWatersupplierUrl = 'api/admin/add/watersupplier';
    static getWatersupplierInfoUrl = 'api/admin/get/watersupplier/info';
    static editWatersupplierUrl = 'api/admin/edit/watersupplier';
    static deleteWatersupplierUrl = 'api/admin/delete/watersupplier';
    
    //coupon-codes
    static fetchallCouponCodesUrl = 'api/admin/fetch/all/coupons';
    static fetchallConstantsUrl = 'api/admin/getconstants';
    static getCOuponCodeDetails = 'api/admin/fetch/coupon/details/couponid';
    static editConstantsUrl = 'api/admin/edit/constants'; 
    static addCouponCodeUrl = 'api/admin/add/new/coupon/code/to/couponmodel';
    static editCouponCodeUrl = 'api/admin/edit/coupon/code/details';
    static deleteCouponCodeUrl = 'api/admin/delete/the/coupon';
    static fetchCouponCodeInfoUrl = 'api/admin/fetch/coupon/details/couponid';
    static activateCouponCodeUrl = 'api/admin/activate/coupon/code';
    static deactivateCouponCodeUrl = 'api/admin/inactivate/coupon/code';
    //membership
    static fetchMembershipDetailsUrl = 'api/admin/get/membership/plan/details';
    static editMembershipDetailsUrl = 'api/admin/edit/membership/plan';
    static deleteMembershipPlanUrl = 'api/admin/delete/membership/plan';
    static addMembershipPlanUrl = 'api/admin/add/memebership/plan';
    static getMembershipPlansUrl = 'api/admin/get/allMembershipPackages';
    static retrieveOrderListByOrderStatusUrl = 'api/admin/retrive/orderlist/according/to/orderstatus';
    
    //shops
    static addShopAsDeliveryPartnerUrl = 'api/admin/add/shopfordelivery';
    static editShopDetailsUrl = 'api/admin/edit/shop/for/delivery';
    static fetchAllShopsUrl = 'api/admin/fetch/all/shops';
    static getShopDetailsUrl = 'api/admin/get/shop/details';
    static deleteShopDetailsUrl = 'api/admin/delete/shop/details';
    static fetchShopLocationUrl = 'api/admin/fetch/shop/location';
    static getAllShopsInDeliveryAreaUrl = 'api/admin/get/all/delivery/shops/in/delivery/area';
    static collectCashFromShopUrl = 'api/admin/collect/cash/from/delivery/shops';
    static displayTransactionHistoryOfShopUrl = 'api/admin/display/transaction/history/of/shops';
    static giveCashToShopUrl = 'api/admin/give/cash/to/delivery/shops'
    //userAdmin
    static getUserDetailsUrl = 'api/admin/fetch/userdetails/admin';
    static getOrderDetailsUrl = 'api/admin/fetch/user/order/details/admin';
    static displayInstallationSlotsUrl = 'api/admin/user/display/selectinstallationslot/panel/admin';
    static bookInstallationSlotUrl = 'api/admin/user/book/installationslot/from/thedisplayedone/admin';
    static cancelBookedSlotUrl = 'api/admin/user/cancel/installation/slot/order/admin';
    static editNameUrl = 'api/admin/update/user/name/admin';
    static editEmailUrl = 'api/admin/update/user/email/admin'; 
    static getPreviusOrdersUrl = 'api/admin/getpreviousorders/of/user/admin';
    static getUserDepositsUrl = 'api/admin/user/get/deposits/data/admin';
    static getUserDepositTransactionsUrl = 'api/admin/user/get/user/deposit/transactions/admin';
    static changeQuantityModifyPackageUrl = 'api/admin/user/modify/package/change/quantity/admin';
    static getUserMembershipPlanDetailsUrl = 'api/admin/user/get/user/membership/details/admin';
    static getWalletSummaryUrl = 'api/admin/get/wallet/summary/for/user/admin';
    static getWalletAmountUrl = 'api/admin/get/money/info/in/wallet/admin';
    static getUserAddressDetailsUrl = 'api/admin/get/user/address/details';
    static attachShopArrayToCustomerUrl = 'api/admin/attach/near/shop/array/to/user/model';
    static dettachShopArrayFromCustomerUrl = 'api/admin/dettach/shop/array/from/user/model';
    //orders
    static placeOrderCODAdminUrl = 'api/admin/verify/user/otp/and/take/cod/payment'; //from admin-user
    static fetchOrderListByOrderStatusUrl = 'api/admin/retrive/orderlist/according/to/orderstatus';
    static cancelOrderUrl = 'api/admin/cancel/order';
    static confirmOrderUrl = 'api/admin/confirm/order';
    static assignDeliveryBoyUrl = 'api/assign/delivery/boy';
    static acceptOrderOnBehalfOfDelBoyUrl = 'api/admin/accept/order/on/behhalf/of/delivery/boy';
    static markDelBoyEnroutePickupUrl = 'api/admin/mark/delivery/boy/enroute/pickup';
    static markDelBoyAtpickupUrl = 'api/admin/mark/Delivery/Boy/At/Pickup';
    static markDelBoyEnrouteDeliveryUrl = 'api/admin/mark/delivery/boy/enroute/delivery';
    static activateDeviceUrl = 'api/admin/activate/user/device';
    static assignInstallationBoyUrl = 'api/admin/assign/installation/boy/to/order';
    static installationInProcessUrl = 'api/admin/mark/installation/in/process';
    static installationCompletedUrl = 'api/admin/mark/installation/complete';
    static outForDeliveryStatusUrl = '';
    static fetchOrderDetailsUrl = 'api/admin/get/order/details';
    static fetchDeliveryBoyDetailsForThatDelAreaUrl = 'api/admin/get/deliveryboys/per/delivery/area';
    static assignOrderToDeliveryBoyUrl = 'api/admin/send/assigned/order/to/deliveryboy';
    static getUnassignedOrdersForDeliveryUrl = 'api/admin/get/unassigned/orders/for/delivery';
    static getAssignedOrdersForDeliveryUrl = 'api/admin/get/assigned/delivery/orders';
    static getOrdersWithInstallationSlotSelectedUrl = 'api/admin/get/orders/with/installation/slot/selected';
    static getOrdersWithInstallationSlotNotAssignedUrl = 'api/admin/get/orders/with/installation/slot/not/assigned';
    static markInstallationCompleteUrl = 'api/admin/mark/installation/complete';
    static markDeliveryCompleteUrl = 'api/admin/mark/delivery/boy/complete/delivery';
    static attachDeviceToUserUrl = 'api/installation/map/device/to/user';
    static refundMoneyToUserUrl = 'api/admin/refund/money/to/user/wallet';
    //tracking codes
    static addNewTrackingCodeUrl = 'api/admin/add/new/tracking/code';
    static getTrackingCodeInfoUrl = 'api/admin/get/tracking/code/info';
    static fetchAllTrackingCodesUrl = 'api/admin/get/all/tracking/codes';
    static resetTrackingCodeUrl = 'api/admin/reset/tracking/code';
    static editTrackingCodeUrl = 'api/admin/edit/tracking/code';
    static deleteTrackingCodeUrl = 'api/delete/tracking/code';
    static markDelBoyCompletedpickupUrl = 'api/admin/mark/delivery/boy/completed/pickup';
    //delivery area
    static attachDeliveryBoyToDeliveryAreaUrl = 'api/admin/attachdeliveryguy/to/delivery/area';
    static attachInstallationBoyToDeliveryAreaUrl = 'api/admin/attachinstallationguy/to/delivery/area';
    static deleteDeliveryBoyAttachedToDelAreaUrl = 'api/admin/delete/delivery/boy/attached/to/delArea';
    static deleteInstallationBoyAttachedToDelAreaUrl = 'api/admin/delete/installation/boy/attached/to/delArea';

    //admin login

    static adminLoginUrl = 'api/admin/login';
    static fetchConstantsUrl = 'api/admin/getconstants';
    static updateConstantsUrl = 'api/admin/update/constants';
    static placeOrderThroughAppUrl = 'api/admin/place/order/app/on/behalf/of/user';
    static sendMessageToRegisteredUserUrl = 'api/admin/send/message/to/user/through/admin/panel';
    static fetchAdminDetailsUrl = 'api/admin/fetch/details';

    //user address
    static getUserAddrDetailsUrl = 'api/admin/get/user/address/details';
    static editUserAddresssDetails = 'api/admin/edit/address/of/user';
    static editAddressUrl = 'api/admin/edit/address/of/user';
    static generateRazorPayLinkUrl = 'api/admin/user/generate/razorpay/link/for/user';
    static addSearchAddressToUserUrl = 'api/admin/add/search/address/to/user';

    //Ticket Generation
    static generateTicketUrl = 'api/admin/generate/user/ticket';

    static getAccessoriesCusUrl = 'api/cart/webapp/getAccessories';
    static fetchAllMembershipSchemes = 'api/admin/fetch/all/membership/plans/available'; // cloned from admin general
    static servicableDeliveryAreaWithProductsUrl = 'api/admin/area/in/service/with/products/and/qty'; //replicated for admin from user general
    static deleteCartUrl = 'api/admin/delete/cart/of/user'; //from admin-user
    static initCartUrl = 'api/admin/init/cart'; // from admin user
    static addItemToCart = 'api/addItemToCart'; 
    static adminaddItemToCartUrl = 'api/admin/add/item/to/user/cart';
    static addItemToCartTrial = '';
    static activatePackageForUser = 'api/admin/user/subscribe/to/a/package'; //from admin-user
    static addressOnMarkerChangeUrl = 'api/on/address/marker/change/of/user';
    static attachAddressToUser = 'api/admin/attach/address/to/user'; //from admin-user
    static removeUserAddressUrl = 'api/admin/user/remove/customer/address';
    static removeItemFromCartUrl = 'api/deleteItemFromCart';
    static installationOrderByAdminUrl = 'api/admin/user/place/cod/payment/and/convert/carttoorder';
    static getCartDetails = 'api/getCartDetails';
    static removeAddressAssociatedWithUserUrl = 'api/admin/remove/searchaddress/associated/with/customer';

    //auto delivery boys 
    static getAutoDeliveryBoysUrl = 'api/admin/get/auto/delivery/boys';
    static deleteAutoDelBoyUrl = 'api/admin/delete/auto/delivery/boy';
    static getAutoDelBoyInfoUrl = 'api/admin/get/auto/del/boy/info';
    static displayTransactionHistoryOfDelBoyUrl = 'api/admin/display/transaction/history/of/auto/delivery/boy';
    static transferCashToDelBoyAccountUrl = 'api/admin/transfer/cash/to/del/boy/account';
    static recieveCashFromAutoDelBoyAccountUrl = '';
    static SignupAutoDelBoyUrl = 'api/admin/signup/auto/del/boy';
    static editAutoDelBoyUrl = 'api/admin/edit/auto/del/boy';

    //vehicles
    static getUnassignedVehiclesUrl = 'api/admin/get/unassigned/cycles';
    static fetchUnassignedOrIncompleteOrdersUrl = 'api/admin/fetch/assigned/or/incomplete/orders';
    static  getAllVehiclesUrl = 'api/admin/get/all/vehicles';
    static  deleteVehicleUrl  = 'api/admin/delete/vehicle';
    static getVehicleInfoUrl = 'api/admin/get/vehicle/info';
    static addVehicleUrl = 'api/admin/add/vehicle';
    static editVehicleUrl = 'api/admin/edit/vehicle';
    static activateVehicleUrl = 'api/admin/activate/vehicle';
    static deactivateVehicleUrl = 'api/admin/deactivate/vehicle';
    static assignDelBoyToSlotOneUrl = 'api/admin/assign/Del/Boy/To/SlotOne';
    static assignDelAreaToSlotTwoUrl = 'api/admin/assign/Del/Boy/To/SlotTwo';
    static assignDelAreaToSlotThreeUrl = 'api/admin/assign/Del/Boy/To/SlotThree';
    static assignDelAreaToSlotFourUrl = 'api/admin/assign/Del/Boy/To/SlotFour';
    static removeDelBoyFromSlotOneUrl = 'api/admin/remove/Del/Boy/from/vehicle/SlotOne';
    static removeDelBoyFromSlotTwoUrl = 'api/admin/remove/Del/Boy/from/vehicle/SlotTwo';
    static removeDelBoyFromSlotThreeUrl = 'api/admin/remove/Del/Boy/from/vehicle/SlotThree';
    static removeDelBoyFromSlotFourUrl = 'api/admin/remove/Del/Boy/from/vehicle/SlotFour';
    static  removeCurrentOrderUrl = 'api/admin/remove/assigned/order/array/from/current/order/array';
    static removeAssignedOrderArrayFromPastOrderArrayUrl = 'api/admin/remove/assigned/order/array/from/past/order/array';
    static removeAssignedOrderArrayFromFutureArrayUrl = 'api/admin/move/assigned/order/array/from/future/to/current/orderarray';
    static removePastOrderUrl = 'api/admin/remove/assigned/order/array/from/past/order/array';
    static removeFutureUrl = 'api/admin/remove/assigned/order/array/from/future/order/array';
    static removeCurrentFromFutureOrderUrl = 'api/admin/remove/assigned/order/array/from/future/order/array';

    //orders
   // static fetchUnAssignedOrdersUrl = 'api/admin/fetch/unassigned/uncompleted/novehicleassigned/orders';
    static fetchAllAssignedOrdersUrl = 'api/admin/fetch/all/assigned/order/array/docs';
    static deliveriesInProgressUrl = 'api/admin/fetch/all/assigned/order/array/docs/with/deliveries/in/progress';
    static vehicleNotAssignedUrl = 'api/admin/fetch/unassigned/orderarray/not/vehicle/assigned';
    static editOrderArrayUrl = 'api/edit/assigned/orders/array/doc';
    static fetchOrdersArrUrl = 'api/fetch/orders/array/on/passing/id';
    static removeVehoicleForAssignedArrayUrl = 'api/admin/remove/vehicle/for/assigned/order/array/doc';
    static createOrderArrUrl = 'api/create/assigned/orders/array';
    static assignVehicleUrl = 'api/admin/assign/or/reassign/vehicle/for/assignedOrderArray';
    static getNoOfUnassignedOrderUrl = 'api/admin/fetch/no/of/unassigned/orders';
    static getNoOfAssignedOrdersUrl = 'api/admin/get/no/of/assigned/orders';
    static getNoOfCompletedOrdersUrl = 'api/admin/get/no/of/completed/orders';
    static getNoOfInProgressOrdersUrl = 'api/admin/get/no/of/in/progress/orders'; 
    static getNoOfUnassignedOrdersUrl = 'api/admin/fetch/non/assigned/orders';
    static fetchinprogressordersUrl = 'api/admin/fetch/in/progress/orders';
    static fetchCompletedOrdersUrl = '';


    //delivery staff
    static getAllDeliveryStaffUrl = 'api/admin/get/all/delivery/staff';//from admin-general
    static getDeliveryStaffInfoUrl = '';
    static deleteDeliveryStaffUrl = 'api/admin/delete/delivery/staff';
    static editDeliveryStaffUrl = 'api/admin/edit/delivery/staff';//from  admin-genaral
    static addDeliveryStaffUrl = 'api/admin/add/deliverystaff'; //from admin-general
    static activateDeliveryStaffUrl = 'api/admin/activate/delivery/staff';
    static deactivateDeliveryStaffUrl = 'api/admin/deactivate/delivery/staff';
    static displayTransactHistoryDelBoyUrl = 'api/admin/display/transaction/history/delivery/boy';
    static receiveCashFromDeliveryoyAccountUrl = 'api/admin/receive/cash/from/delivery/boy';
    static dettachVehicleUrl = 'api/admin/dettach/vehicle';
    static attachVehicleUrl = 'api/admin/attach/vehicle';



}
