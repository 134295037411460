<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Inventories</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="card px-3">
                <div class="col-12 mt-4">
                  <button type="button" routerLink="/inventories/add-inventory/new" class="btn btn-primary btn-lg mb-3">
                    Add Inventory
                  </button>
                </div>
                <!-- Search Location Section -->
                <div class="col-12">
                  <app-search-bar (searchDataRecieved)="searchDataRecieved($event)"></app-search-bar>
                  </div>
                <!-- Map Section -->
                <div class="col-12 my-3" *ngIf="apiLoaded | async">
                  <google-map height="450px" width="100%" [center]="center" [zoom]="zoom">
                    <ng-container *ngFor="let data of inventories">
                      <ng-container *ngIf="data?.address">
                        <map-marker #marker="mapMarker" [position]="data.coordinates" [options]="markerOptionsInventory" (mapDragend)="markerDragEnd($event)"
                          (mapClick)="openInfoWindow(marker, infoWindow)">
                        </map-marker>
                        <!-- Info Window -->
                        <map-info-window #infoWindow="mapInfoWindow">
                          <div class="info-window-small">
                            <div class="info-window-header">
                              Inventory-ID #{{ data._id }}
                            </div>
                            <div class="info-window-body">
                              <p>
                                <strong>Capacity:</strong> {{ data.capacity }}
                              </p>
                              <p>
                                <strong>Primary Address:</strong>
                                {{ data?.address?.place }}
                              </p>
                              <p>
                                <strong>Secondry Address:</strong>
                                {{ data?.address?.secondaryAddress }}
                              </p>
                              <p>
                                <strong>Landmark:</strong>
                                {{ data?.address?.landmark }}
                              </p>
                              <!-- Buttons View And Delete -->
                              <div class="d-flex justify-content-between align-content-center gap-2">
                                <button (click)="editInventory(data._id)" class="btn btn-add">
                                  <span>Edit</span>
                                </button>
                                <button (click)="deleteInventoryData(data._id)" class="btn btn-remove">
                                  <span>Delete</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </map-info-window>
                        </ng-container>
                        </ng-container>
                        </google-map>
                        </div>
              </div>
            </div>
            </div>
        </div>
        <div class="card-body">
          <div class="row">
              <div class="col-12 mt-5">
                <div class="user-status table-responsive products-table">
                  <table class="table table-bordernone mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Inventory Name</th>
                        <th scope="col">Capacity</th>
                        <th scope="col">Primary Address</th>
                        <th scope="col">Secondary Address</th>
                        <th scope="col">LandMark</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody *ngFor="let data of inventories; let i = index">
                      <tr>
                        <td>{{ data.name }}</td>
                        <td class="digits">{{ data.capacity }}</td>
                        <td >{{ data?.address?.place }}</td>
                        <td>{{ data?.address?.secondaryAddress }}</td>
                        <td>{{ data?.address?.landmark }}</td>
                        <td class="pb-2">
                          <button
                            type="button"
                            [routerLink]="[
                              '/inventories/add-inventory',
                              data._id
                            ]"
                            class="btn btn-outline-warning btn-sm"
                          >
                            Edit
                          </button>
                        </td>
                        <td class="pb-2">
                          <button
                            type="button"
                            (click)="deleteInventoryData(data._id)"
                            class="btn btn-outline-primary btn-sm"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            
          </div>
        </div>
        </div>
    </div>
    </div>
    </div>