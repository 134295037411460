import {
  Injectable
} from '@angular/core';
import {
  CookieService, CookieOptions
} from 'ngx-cookie';
//let CookieOptionsVar = {domain: 'bringjal.com' } as CookieOptions;

@Injectable({
  providedIn: 'root'
})
export class GlobalContextService {
  globalContext: GlobalContext = new GlobalContext();
  constructor(private _cookieService: CookieService) {
    //this._cookieService.check('globalContext
    if (this._cookieService.get('globalContext') != null) {
      // We Will decrypt here and assign
      this.globalContext = JSON.parse(this._cookieService.get('globalContext'));
    }

  }


  // setGlobalContext() {
  //   //console.log("Setting global context object to a new value", this.globalContext);
  //   //We will encrypt and store here later
  //   //localStorage.setItem("sessionID", JSON.stringify(this.globalContext.userData.token));
    
  //   //this._cookieService.set('globalContext', JSON.stringify(this.globalContext), undefined, "/");
  //   this._cookieService.put('globalContext', JSON.stringify(this.globalContext));

  // }

  setGlobalContext() {
      console.log("Setting global context object to a new value", this.globalContext);
      //We will encrypt and store here later
      //localStorage.setItem("sessionID", JSON.stringify(this.globalContext.userData.token));
      
      //this._cookieService.set('globalContext', JSON.stringify(this.globalContext), undefined, "/");
      //this._cookieService.put('globalContext', JSON.stringify(this.globalContext), CookieOptionsVar);
      this._cookieService.put('globalContext', JSON.stringify(this.globalContext));
    }
}

export class GlobalContext {

  userToken: any;
  userData: any;
  configurationdata: any;
  //  cartData: any;
  //  productData: any;
  //  accessoryData: any;
  // 
  //  walletData: any;
  //  walletAmountData: any;
  //  locationData: any;

}
