<div class="container-fluid">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <!-- <p class="text-center"><b>Edit Profile</b></p> -->

                <div class="col-12">
                    <div class="form-floating mb-3">
                        <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
                        <label for="floatingInput">Enter Issue </label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-floating">
                        <textarea class="form-control" placeholder="Leave a comment here"
                            id="floatingTextarea"></textarea>
                        <label for="floatingTextarea">Comments</label>
                    </div>

                </div>
                <div class="col-12 my-3">
                    <select class="form-select form-select-lg mb-3" aria-label="Large select example">
                        <option selected>Ticket Status</option>
                        <option value="Open">Open</option>
                        <option value="Inprocess">Inprocess</option>
                        <option value="Closed">Closed</option>
                    </select>
                </div>
                <div class="col-12">
                    <div class="d-grid col-6 gap-2 mx-auto">
                        <button class="btn btn-secondary" type="button"
                           (click)="submit()">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>