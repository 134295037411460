import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListUserComponent } from './list-user/list-user.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { SearchUserComponent } from './search-user/search-user.component';
import { PlaceAndTrackOrderComponent } from './place-and-track-order/place-and-track-order.component';
import { PlaceInitialOrderComponent } from './place-initial-order/place-initial-order.component';
import { BringjalWalletComponent } from './bringjal-wallet/bringjal-wallet.component';
import { CustomerSupportComponent } from './customer-support/customer-support.component';
import { EditUserAddressComponent } from './edit-user-address/edit-user-address.component';
import { MembershipComponent } from './membership/membership.component';
import { ModifyQuantityComponent } from './modify-quantity/modify-quantity.component';
import { PreviousOrdersComponent } from './previous-orders/previous-orders.component';
import { AssignDeviceComponent } from './assign-device/assign-device.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { NewTicketComponent } from './new-ticket/new-ticket.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list-user',
        component: ListUserComponent,
        data: {
          title: "User List",
          breadcrumb: "User List"
        }
      },
      {
        path: 'create-user',
        component: CreateUserComponent,
        data: {
          title: "Create User",
          breadcrumb: "Create User"
        }
      },
      {
        path: 'search-user',
        component: SearchUserComponent,
        data: {
          title: "Search User",
          breadcrumb: "Search User"
        }
      },
      {
        path: 'assign-device',
        component: AssignDeviceComponent,
        data: {
          title: "Assign Device",
          breadcrumb: "Assign Device"
        }
      },
      {
        path: 'place-order',
        component: PlaceAndTrackOrderComponent,
        data:{
          title: "Place And Track Order",
          breadcrumb: "Place Order"
        }
      },
      {
        path: 'initial-order',
        component: PlaceInitialOrderComponent,
        data:{
          title: "Place Initial Order",
          breadcrumb: "Place Initial Order"
        }
      },
      {
        path: 'bringjal-wallet',
        component: BringjalWalletComponent,
        data:{
          title: "Bringjal Wallet",
          breadcrumb: "Bringjal Wallet",
        }
      },
      {
        path: 'customer-support',
        component: CustomerSupportComponent,
        data:{
          title: "Customer Support",
          breadcrumb: "Customer Support",
        }
      },
      {
        path: 'edit-user-address',
        component: EditUserAddressComponent,
        data:{
          title: "Edit User Address",
          breadcrumb: "Edit User Address",
        }
      },
      {
        path: 'membership',
        component: MembershipComponent,
        data:{
          title: "Membership",
          breadcrumb: "Membership",
        }
      },
      {
        path: 'modify-quantity',
        component: ModifyQuantityComponent,
        data:{
          title: "Modify Quantity",
          breadcrumb: "Modify Quantity",
        }
      },
      {
        path: 'previous-orders',
        component: PreviousOrdersComponent,
        data:{
          title: "Previous Orders",
          breadcrumb: "Previous Orders",
        }
      },
      {
        path: 'new-ticket',
        component: NewTicketComponent,
        data:{
          title: "New Ticket",
          breadcrumb: "New Ticket",
        }
      },
      {
        path: 'edit-profile',
        component: EditProfileComponent,
        data:{
          title: "Edit User Profile",
          breadcrumb: "Edit Profile",
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
