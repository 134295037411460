import { inject, Injectable } from "@angular/core";

import {
  BehaviorSubject,
  catchError,
  Observable,
  Observer,
  throwError,
  timeout,
} from "rxjs";
import { AppConstants } from "../entities/appconstants";
import { globalErrorHandler } from "./error-handler";
import { GlobalHttpService } from "./globalhttpservice.service";
import { id } from "@swimlane/ngx-charts";
import { JwtHelperService } from "@auth0/angular-jwt";
import { GlobalContextService } from "./globalstorage.service";
import { CookieStorageService } from "./cookie-storage.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn:'root'
})
export class UserBackupService {
  token: string;
  user: any;
  httpClient = inject(HttpClient);

  constructor(
    private _globalHttpService: GlobalHttpService,
    private _globalErrorHandler: globalErrorHandler,
    private _cookieStorageService: CookieStorageService,
    private _globalStorageService: GlobalContextService
  ) {
    this._globalStorageService.getUser.subscribe((user) => {
      if (user.userToken) {
        this.token = user.userToken;
        this.user = user.userData;
      }
    });
  }

  // fetchActiveOrdersForMap() {
  //   return this.httpClient.post<{ Total: number; data: any }>(
  //     "https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/getPendingamountdetails",
  //     {}
  //   );
  // }

  

  // customer
  searchUserById(id: string) {
    console.log("searchuserbyid",id);
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });

    return this.httpClient.post<{ data: any }>(
      `https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/api/user/find/user/details/when/id/given`,{userid:id},
      { headers }
    );
  }

 


  // mobileNumberEdit(mobile:string,id: string) {
  //   console.log(id)
  //   var headers=new HttpHeaders({
  //     'Content-Type':'application/json',
  //     'token':this.token
  //   })

  //   return this.httpClient.post<{ result: any}>(`https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/api/admin/edit/mobile/user${id}`,{mobile},{headers});
  // }

  // mobile
  searchUserByMobile(mobile: number) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    return this.httpClient.post<{
      success: boolean;
      message: string;
      result: any;
    }>(
      "https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/api/admin/search/user/by/mobile/number",
      {
        mobile: mobile,
      },
      { headers }
    );
    // https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/api/admin/search/user/by/mobile/number
  }

  //order

  placeOrderThroughApp(userId: string) {
    return this.httpClient
      .post<{ message: string; order: any; userdata: any }>(
        "https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/placeOrderApp",
        { requestID: userId }
      )
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }
  getUserOrderById(id: string) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });

    return this.httpClient.post<{ message: string; data: any }>(
      "https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/api/watercan/project/user/get/order/info",
      { id },
      { headers }
    );
  }


  cancelOrder(orderId: string) {
    console.log(orderId);
    return this.httpClient
      .post(
        "https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/cancelOrder",
        {
          orderid: orderId,
        }
      )
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }

  markOrderComplete(orderId: string) {
    return this.httpClient.post<{ message: string; result: any }>(
      `https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/markOrderComplete/${orderId}`,
      {}
    );
  }


  // membership

  addMembership(userId: string) {
    return this.httpClient.post<{ message: string; data: any }>(
      "https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/api/admin/add/membership/to/user",
      {
        userid: userId,
      }
    );
  }

  extendMembership(userId: string, month: number) {
    return this.httpClient.post(
      "https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/extendUserMembership",
      {
        userid: userId,
        months: month,
      }
    );
  }

  // wallet

  getWalletSummaryOfUser(limitNum: number, userId: string) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    return this.httpClient.post<{
      message: string;
      result: [
        {
          BringJalWallet: string;
          amount: number;
          description: string;
          transaction_date: Date;
          transaction_type: { credit: boolean; debit: boolean };
          user: string;
          _id: string;
        }
      ];
    }>(
      "https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/api/admin/get/wallet/summary/for/user/admin",
      {
        userid: userId,
        limitNum: limitNum,
      },
      { headers }
    );
  }
  getWalletAmountOfUser(userId: string) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    return this.httpClient.post<{
      message: string;
      result: any;
    }>(
      "https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/api/admin/get/wallet/amount/for/user/admin",
      {
        userid: userId,
      },
      { headers }
    );
  }
  refunMoneyTowallet(userId: string, amount: number, message: string) {
    return this.httpClient.post(
      "https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/api/admin/refund/money/to/user/wallet",
      {
        userid: userId,
        amount: amount,
        message: message,
      }
    );
  }

  

  

  // mobile edit
  mobileNumberEdit(mobile: string, userId: string) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    console.log(mobile, userId);
    return this.httpClient
      .post<{ message: string; result: any }>(
        `https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/api/watercan/whatsapp/modify/mobile/num`,
        { mobile: mobile, userid: userId },
        { headers }
      )
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }

  // address

  getUserAddress(userId: string) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    return this.httpClient
      .get<{ message: string; result: any }>(
        `https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/user/address/details/${userId}`,
        { headers }
      )
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }


  editUserAddress(
    userId: string,
    primary_address: string,
    secondary_address: string,
    lift: boolean,
    floor: number,
    longitude: number,
    latitude: number,
    landmark: string,
    delivery_note: string
  ) {
    return this.httpClient
      .post(
        `https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/api/admin/edit/address/of/user/${userId}`,
        {
          primary_address,
          secondary_address,
          lift,
          floor,
          longitude,
          latitude,
          landmark,
          delivery_note,
        }
      )
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }

  addressOnMarkerChange(
    userId: string,
    latitude: number,
    longitude: number,
    primary_address: string
  ) {
    console.log(latitude, longitude, userId);
    var headers = new HttpHeaders({
      "Content-type": "application/json",
      token: this.token,
    });
    return this.httpClient.post<{ result: any }>(
      `https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/api/admin/on/address/marker/change/of/user`,
      { coordinates: [longitude, latitude], userid: userId },
      { headers }
    );
  }

  // active order
  sendWhatsAppMessage(
    amount: number,
    order_id: string,
    userid: string,
    username: string,
    notes: string,
    mobile: string
  ) {
    const payload = {
      type: "Admin",
      amount,
      order_id,
      userid,
      username,
      notes,
      mobile,
    };
    const headers = new HttpHeaders({
      "Content-Type": "application/json", // Specify the content type
    });
    return this.httpClient
      .post(
        "https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/createAGeneralPayLink",
        payload,
        { headers }
      )
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }

 
}

