<div class="container-fluid">
   <div class="card">
    <div class="card-header">
        <h5>Place Initial Order</h5>
    </div>
    <div class="card-body">
        @if(address){
            Add address
        }
        @if(!detailsSubmitted){
            <div class="row my-2">
                <div class="col-12">
                    <h5><b>Product Name:</b>Water</h5>
                </div>
                <div class="col-12">
                    <h5><b>Quantiity:</b></h5>
                    <select class="form-select form-select-sm mb-3" aria-label="Large select example">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                </div>
                <div class="col-12">
                    <h5><b>Device needed:</b></h5>
                    <div class="d-flex">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                            <label class="form-check-label" for="flexRadioDefault1">
                              Yes
                            </label>
                          </div>
                          <div class="form-check mx-3">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
                            <label class="form-check-label" for="flexRadioDefault2">
                              No
                            </label>
                          </div>
                    </div>
                </div>
                <div class="col-12">
                    <h5><b>Add Accessories:</b></h5>
                </div>
                <div class="row">
                    <div class="col-md-4 col-12">
                        <div class="card" style="width: 18rem;">
                            <img src="..." class="card-img-top" alt="...">
                            <div class="card-body">
                              <h5 class="card-title">Device title</h5>
                              <p class="card-text"></p>
                              <a href="" class="btn btn-primary float-end">Add <i class="bi bi-plus-square"></i></a>
                            </div>
                          </div>
                    </div>
                    <div class="col-md-4 col-12">
                        <div class="card" style="width: 18rem;">
                            <img src="..." class="card-img-top" alt="...">
                            <div class="card-body">
                              <h5 class="card-title">Device title</h5>
                              <p class="card-text"></p>
                              <a href="" class="btn btn-primary float-end">Add <i class="bi bi-plus-square"></i></a>
                            </div>
                          </div>
                    </div>
                    <div class="col-md-4 col-12">
                        <div class="card" style="width: 18rem;">
                            <img src="..." class="card-img-top" alt="...">
                            <div class="card-body">
                              <h5 class="card-title">Device title</h5>
                              <p class="card-text"></p>
                              <a href="" class="btn btn-primary float-end">Add <i class="bi bi-plus-square"></i></a>
                            </div>
                          </div>
                    </div>
                    <div class="col-md-4 col-12">
                        <div class="card" style="width: 18rem;">
                            <img src="..." class="card-img-top" alt="...">
                            <div class="card-body">
                              <h5 class="card-title">Device title</h5>
                              <p class="card-text"></p>
                              <a href="" class="btn btn-primary float-end">Add <i class="bi bi-plus-square"></i></a>
                            </div>
                          </div>
                    </div>
                </div>
                <div class="col-12">
                    <h5><b>Address:</b></h5>
                </div>
                <div class="col-12"  *ngIf="apiLoaded | async">
                   <google-map
                        height="450px"
                        width="100%"
                        [center]="center"
                        [zoom]="zoom">
                        <map-marker
                        [position]="marker.position"
                        [options]="marker.options"
                        (mapDragend)="markerDragEnd($event, marker)">
                        </map-marker>
                    </google-map>
                </div>
                <div class="col-12">
                    <h5><b>Primary Address:</b></h5>
                    <input type="text" readonly class="form-control-plaintext" id="floatingEmptyPlaintextInput" placeholder="NGEF Layout, Sadahana Naga">
                </div>
                <div class="col-12">
                    <h5><b>Secondary Address:</b></h5>
                    <input type="text" class="form-control"  placeholder="">
                </div>
                <div class="col-12">
                    <h5><b>Land mark:</b></h5>
                    <input type="text" class="form-control"  placeholder="">
                </div>
                <div class="col-12">
                    <h5><b>Floor:</b></h5>
                    <input type="text" class="form-control"  placeholder="">
                </div>
                <div class="col-12">
                    <h5><b>Lift Available:</b></h5>
                    <div class="d-flex">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                            <label class="form-check-label" for="flexRadioDefault1">
                              Yes
                            </label>
                          </div>
                          <div class="form-check mx-3">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
                            <label class="form-check-label" for="flexRadioDefault2">
                              No
                            </label>
                          </div>
                    </div>
                </div>

                <div class="col">
                        <div class="d-grid gap-2 col-6 mx-auto">
                            <button type="button" class="btn btn-secondary" (click)="submitDetails()">Submit</button>
                        </div>
                </div>
            </div>
        }
        @if(detailsSubmitted){
            <div class="row my-3">
                <div class="col-12">
                    <h5><b>Product Name:</b> Watercans </h5>
                </div>
                <div class="col-12">
                    <h5><b>Bringjal Wallet Ammount:</b>&#8377; 2550</h5>
                </div>
                <div class="col-12">
                    <h5><b>Quality:</b> 5</h5>
                </div>
                <div class="col-12">
                    <h5><b>Watercans Ammount:</b>&#8377; 250</h5>
                </div>
                <div class="col-12">
                    <h5><b>Watercans Deposite Ammount:</b>&#8377; 550</h5>
                </div>
                <div class="col-12">
                    <h5><b>Device deposite Ammount:</b>&#8377; 350</h5>
                </div>
                <div class="col-12">
                    <h5><b>Accessories:</b>Droppy-/ &#8377; 250</h5>
                </div>
                <div class="col-12">
                    <h5><b>Total amount to be paid:</b> 1200</h5>
                </div>
            </div>
        }
    </div>
   </div>
</div>