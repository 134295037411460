import { HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { Component, CUSTOM_ELEMENTS_SCHEMA, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { catchError, map, Observable, of } from 'rxjs';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-place-initial-order',
  templateUrl: './place-initial-order.component.html',
  styleUrl: './place-initial-order.component.scss'
})
export class PlaceInitialOrderComponent {
  address: boolean = false;
  detailsSubmitted: boolean = false;

  latitude: number;
  longitude: number; 
  addr: string;
  private primaryAddress: string;
  place: any;
  apiLoaded: Observable<boolean>;

  @ViewChild(GoogleMap, {
    static: false
  }) set map(m: GoogleMap) {
    if (m) {
    }
  }

  options: google.maps.MapOptions = {
    zoom: 15
    //disableDefaultUI: true
  };
  center: google.maps.LatLngLiteral = {
    lat: 12.977717,
    lng: 77.676492
  };


  constructor(private httpClient: HttpClient) {
    setTimeout(() => {
      this.apiLoaded = httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyC2kDhE4vTqxKvhe4OBOZLpjlptfB5M_zU&libraries=drawing', 'callback').pipe(map(() => true), catchError(() => of(false)));

    }, 5000);
    
   }

  ngOnInit(): void {

  }

  // center: google.maps.LatLngLiteral = { lat: 24, lng: 12 };
  zoom = 14;
  marker = 
    {
      position: { lat: 12.977717,
        lng: 77.676492 },
      options: { draggable: true }
    };

  markerDragEnd(event: google.maps.MapMouseEvent, marker: any) {
    console.log('Marker drag end', event.latLng.toJSON());
    marker.position = event.latLng.toJSON();
  }
  
  public openInfoWindow(marker: MapMarker, infoWindow: MapInfoWindow) {
    infoWindow.open(marker);
  }

  submitDetails(){
    this.detailsSubmitted = true;
    console.log("Details submitted successfully");
  }
}
