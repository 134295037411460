import { Component } from '@angular/core';


interface Alert {
  message: string;
  type: string;
}

@Component({
  selector: 'app-modify-quantity',
  standalone: true,
  imports: [],
  templateUrl: './modify-quantity.component.html',
  styleUrl: './modify-quantity.component.scss'
})

export class ModifyQuantityComponent {

  alert: Alert | null = null;

  appendAlert(message: string, type: string): void {
    this.alert = { message, type };
  }

  removeAlert(): void {
    this.alert = null;
  }

  triggerAlert(): void {
    this.appendAlert('Enter atleast minimum quantity of cans according to thi ', 'primary');
  }

}
