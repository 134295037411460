<div class="container-fluid">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <!-- <p class="text-center"><b>Edit Profile</b></p> -->

                <div class="col-12">
                    <div class="form-floating mb-3">
                        <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
                        <label for="floatingInput">Enter name</label>
                    </div>
                    </div>
                <div class="col-12">
                <div class="form-floating mb-3">
                    <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
                    <label for="floatingInput">Enter mobile number</label>
                </div>
                <div class="col-12">
                    <div class="form-floating mb-3">
                        <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
                        <label for="floatingInput">Enter email id</label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="d-grid col-6 gap-2 mx-auto">
                        <button class="btn btn-secondary" type="button" routerLink="/users/list-user">Update Profile</button>
                      </div> 
                </div>
            </div> 
        </div>
    </div>
</div>