import { Component, inject, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CookieStorageService } from './shared/service/cookie-storage.service';
import { BehaviorSubject } from 'rxjs';
import { GlobalContextService } from './shared/service/globalstorage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'multikart-backend';
  loader:boolean=false
   
  constructor(private cookieService:CookieService){


  }

  ngOnInit(): void {
  
  }
  

}
