import { JsonPipe, NgClass, NgFor, NgIf, NgTemplateOutlet } from "@angular/common";
import { Component, Input, NgModule, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  ReactiveFormsModule,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { UserBackupService } from "src/app/shared/service/user-backup.service";
import { SharedModule } from "src/app/shared/shared.module";

@Component({
  selector: 'app-customer-order',
  standalone: true,
  imports: [SharedModule, ReactiveFormsModule,NgClass,NgIf,NgFor,NgTemplateOutlet,JsonPipe],
  templateUrl: './customer-order.component.html',
  styleUrl: './customer-order.component.scss'
})
export class CustomerOrderComponent {
  @Input({required:true}) user:any
  order:any=null
  orderItem=[]
  loader:boolean=false
  errorMessage:string|null=null
  constructor(
    private _UserBackupService: UserBackupService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {} 
  dummyData = [
    {
      item_id: 'item-1234',
      item_name: 'Item 1',
      item_type: 'Product',
      item_base_price: 75,
      per_item_deposit: 10,
      item_description: 'Description for Item 1',
      item_quantity: 3,
    },
    {
      item_id: 'item-5678',
      item_name: 'Item 2',
      item_type: 'Service',
      item_base_price: 50,
      per_item_deposit: 5,
      item_description: 'Description for Item 2',
      item_quantity: 1,
    },
    {
      item_id: 'item-9101',
      item_name: 'Item 3',
      item_type: 'Product',
      item_base_price: 100,
      per_item_deposit: 20,
      item_description: 'Description for Item 3',
      item_quantity: 2,
    },
    {
      item_id: 'item-1121',
      item_name: 'Item 4',
      item_type: 'Product',
      item_base_price: 65,
      per_item_deposit: 15,
      item_description: 'Description for Item 4',
      item_quantity: 4,
    },
    {
      item_id: 'item-3141',
      item_name: 'Item 5',
      item_type: 'Service',
      item_base_price: 30,
      per_item_deposit: 3,
      item_description: 'Description for Item 5',
      item_quantity: 5,
    }
  ];
  ngOnInit(){
    
    if(this.user?.order){
    this._UserBackupService.getUserOrderById(this.user.order).subscribe({
      next:(data) => {
       console.log(data)
       if(data.data){
        this.order = data.data;
        console.log(data.data)
        data.data.order_items.items.forEach(element => {
          this.orderItem.push(element);
        });
        this.loader=false
        this.errorMessage=null
        this.searchCustomer() 
       
      }else{
        this.toastr.error("Error",data.message);
      }
      },
      error:(err)=>{console.log(err.error)
        this.loader=false
        this.errorMessage='Unable to fetch order'
      }
    });
  }
  }

 
  placeOrder(){
    this.loader=true
    this._UserBackupService.placeOrderThroughApp(this.user._id).subscribe({
      next:(data) => {
       console.log(data)
       if(data.order){
        this.order = data.order;
        console.log(data.order)
        data.order.order_items.items.forEach(element => {
          this.orderItem.push(element);
        });
        this.loader=false
        this.errorMessage=null
        this.searchCustomer() 
        this.toastr.success('Hurray', 'Order Successfullt placed');
      }else{
        this.toastr.error("Error",data.message);
      }
      },
      error:(err)=>{console.log(err.error)
        this.loader=false
        this.errorMessage='Unable to place order'
      }
    });
  }
  orderCancel(){
   this.loader=true
    let orderId;
    if(this.order?._id){
      orderId=this.order._id;
    }else {
      orderId=this.user.order
    }
    this._UserBackupService.cancelOrder(orderId).subscribe({
      next:(data) => {
       console.log(data)
       this.order=null
       this.loader=false
       this.searchCustomer()

      },
      error:(err)=>{console.log(err.error.message)
        this.loader=false;
        this.errorMessage="Unable to cancel order"
      }
    });
  }
   markCompleteOrder(){
    let orderId;
    this.loader=true
    if(this.order?._id){
      orderId=this.order._id;
    }else {
      orderId=this.user.order
    }
    this._UserBackupService.markOrderComplete(orderId).subscribe({
      next:(data) => {
       console.log(data)
       this.loader=false
       this.searchCustomer()
      },
      error:(err)=>{console.log(err.error.message)
        this.loader=false
      }
    });
   }

   searchCustomer() {
    
    this._UserBackupService.searchUserByMobile(this.user.mobile).subscribe({
      next:(data)=>{
        this.user=data.result
        console.log(data)
    },
    error:(err)=>console.log(err)
    })
  }
}
