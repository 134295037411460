<div class="container-fluid">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-12 gap-3">
                    <!-- d-grid gap-2 d-md-flex justify-content-md-end -->
                    <div class="d-grid gap-2 justify-content-md-end">
                        <button class="btn btn-secondary btn-sm" type="button">Fetch Minimum Quantity</button>
                      </div> 
                </div>
                <div class="col-12">
                    <h3>Minimum quantity for this user location : 3</h3>
                </div>
                <div class="col-12">
                    <div class="form-floating mb-3" id="liveAlertPlaceholder">
                        <input type="text" class="form-control" id="floatingInput" placeholder="Enter minimum quantity">
                        <label for="floatingInput">Enter minimum quantity</label>
                      </div>
                </div>
            </div>
            <div class="col-12">
                <div class="d-grid col-6 gap-2 mx-auto">
                    <button class="btn btn-secondary" type="button">Submit</button>
                  </div> 
            </div>
        </div>
    </div>

    <!-- <div id="liveAlertPlaceholder">
        <div *ngIf="alert" class="alert alert-{{alert.type}} alert-dismissible" role="alert">
          <div>{{ alert.message }}</div>
          <button type="button" class="btn-close" (click)="removeAlert()" aria-label="Close"></button>
        </div>
    </div> -->

    
</div>