import { inject, Injectable } from "@angular/core";

import {
  BehaviorSubject,
  catchError,
  Observable,
  Observer,
  throwError,
  timeout,
} from "rxjs";
import { AppConstants } from "../entities/appconstants";
import { globalErrorHandler } from "./error-handler";
import { GlobalHttpService } from "./globalhttpservice.service";
import { id } from "@swimlane/ngx-charts";
import { JwtHelperService } from "@auth0/angular-jwt";
import { GlobalContextService } from "./globalstorage.service";
import { CookieStorageService } from "./cookie-storage.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
const helper = new JwtHelperService();
@Injectable({
  providedIn: "root",
})
export class AdminService {
  token: string;
  user: any;
  httpClient = inject(HttpClient);

  constructor(
    private _globalHttpService: GlobalHttpService,
    private _globalErrorHandler: globalErrorHandler,
    private _cookieStorageService: CookieStorageService,
    private _globalStorageService: GlobalContextService
  ) {
    this._globalStorageService.getUser.subscribe((user) => {
      if (user.userToken) {
        this.token = user.userToken;
        this.user = user.userData;
      }
    });
  }

  fetchPastOrdersForMap(numberOfDays, areaArray): Observable<any> {
    var headers = new Headers({
      "Content-Type": "application/json",
    });
    var body = {
      numberOfDays: numberOfDays,
      areaArray: [areaArray],
    };
    return Observable.create((observer: Observer<any>) => {
      var url =
        "https://65xh1slifj.execute-api.ap-south-1.amazonaws.com/dev/api/watercan/project/omnibot/fetch/users/with/required/fields/and/orders";
      this._globalHttpService
        .postDataToServiceFixedApi(url, body, headers)
        .subscribe((data: any) => {
          observer.next(data);
          observer.complete();
        });
    });
  }

  // fetchActiveOrdersForMap(): Observable<any> {
  //   var headers = new Headers({
  //     "Content-Type": "application/json",
  //   });

  //   return Observable.create((observer: Observer<any>) => {
  //     var url =
  //       "https://65xh1slifj.execute-api.ap-south-1.amazonaws.com/dev/api/watercan/project/omnibot/active/users/with/specific/fields";
  //     this._globalHttpService
  //       .postDataToServiceFixedApi(url, null, headers)
  //       .subscribe((data: any) => {
  //         observer.next(data);
  //         observer.complete();
  //       });
  //   });
  // }

  //add delivery area

  //admin login call

  // active order
  fetchActiveOrdersForMap() {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    return this.httpClient.post<{ Total: number; data: any }>(
      "https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/getPendingamountdetails",
      {},{headers}
    );
  }


  // cart

  InitialOrder(package_details: any, accessory_details: any,deliveryAreaId:string) {
    
    if(!package_details.product_id){
      package_details=undefined
    }
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
console.log({ package_details,accessory_details,deliveryAreaId })
    return this.httpClient.post<{ result: any }>(
      `https://rjzrjrv4vd.execute-api.ap-south-1.amazonaws.com/dev/api/admin/cart/creation/with/user/options`,
      { package_details,accessory_details,deliveryAreaId },
      { headers }
    );
  }

  // auth
  adminLogin(mobile: string, password: string) {
    var body = {
      mobile: mobile,
      password: password,
    };
   
    return this.httpClient
      .post<{ token: string,Admindata:any }>(
        "https://3x2rcmdq17.execute-api.ap-south-1.amazonaws.com/dev/api/admin/login",
        body
      )
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }

  adminSignUp(name: string, email: string, mobile: string,residential:boolean) {
    var body = {
      name,
      email,
      mobile,
      residential
    };
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    console.log(body)
    return this.httpClient
      .post<{ token: string }>(
        "https://rjzrjrv4vd.execute-api.ap-south-1.amazonaws.com/dev/api/admin/signup/user/admin",
        body,{headers}
      )
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }

  // customer
  searchUserById(id: string) {
    console.log(id);
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });

    return this.httpClient.get<{ result: any }>(
      `https://rjzrjrv4vd.execute-api.ap-south-1.amazonaws.com/dev/api/admin/fetch/userdetails/admin/${id}`,
      { headers }
    );
  }

  EditUserName(name: string, id: string) {
    console.log(id);
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });

    return this.httpClient.post<{ result: any }>(
      `https://rjzrjrv4vd.execute-api.ap-south-1.amazonaws.com/dev/api/admin/update/user/name/admin/${id}`,
      { name },
      { headers }
    );
  }
  EditUserEmail(email: string, id: string) {
    console.log(id);
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });

    return this.httpClient.post<{ result: any }>(
      `https://rjzrjrv4vd.execute-api.ap-south-1.amazonaws.com/dev/api/admin/update/user/email/admin/${id}`,
      { email },
      { headers }
    );
  }

  IntrestedCustomerOutOfServiceArea(
    name: string,
    email: string,
    mobile: string,
    primary_address: string,
    longitude: number,
    latitude: number
  ) {
    console.log({
      name,
      email,
      mobile,
      primary_address,
      longitude,
      latitude,
    });
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    return this.httpClient
      .post(
        `https://rjzrjrv4vd.execute-api.ap-south-1.amazonaws.com/dev/api/admin/intereated/but/out/of/service/user/admin`,
        {
          name,
          email,
          mobile,
          primary_address,
          longitude,
          latitude,
        },
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }

  // mobileNumberEdit(mobile:string,id: string) {
  //   console.log(id)
  //   var headers=new HttpHeaders({
  //     'Content-Type':'application/json',
  //     'token':this.token
  //   })

  //   return this.httpClient.post<{ result: any}>(`https://rjzrjrv4vd.execute-api.ap-south-1.amazonaws.com/dev/api/admin/edit/mobile/user${id}`,{mobile},{headers});
  // }

  // mobile
  searchUserByMobile(mobile: number) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    console.log(this.token)
    return this.httpClient.post<{
      success: boolean;
      message: string;
      result: any;
    }>(
      "https://rjzrjrv4vd.execute-api.ap-south-1.amazonaws.com/dev/api/admin/search/user/by/mobile/number",
      {
        mobile: mobile,
      },
      { headers }
    );
    // https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/api/admin/search/user/by/mobile/number
  }

  //order

  placeOrderThroughApp(userId: string) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    return this.httpClient
      .post<{ message: string; order: any; userdata: any }>(
        "https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/placeOrderApp",
        { requestID: userId },{headers}
        
      )
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }
  getUserOrderById(id: string) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });

    return this.httpClient.post<{ message: string; data: any }>(
      "https://rjzrjrv4vd.execute-api.ap-south-1.amazonaws.com/dev/api/admin/get/order/details/user",
      { id },
      { headers }
    );
  }
  getUserOrderByShortId(orderid: string) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });

    return this.httpClient.post<{ message: string; result: any }>(
      "https://rjzrjrv4vd.execute-api.ap-south-1.amazonaws.com/dev/api/admin/get/order/details/short/id/user",
      { orderid },
      { headers }
    );
  }

  cancelOrder(orderId: string) {
    console.log(orderId);
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    return this.httpClient
      .post(
        "https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/cancelOrder",
        {
          orderid: orderId,
        },{headers}
      )
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }

  markOrderComplete(orderId: string) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    return this.httpClient.post<{ message: string; result: any }>(
      `https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/markOrderComplete/${orderId}`,
      {},{headers}
    );
  }

  getPreviousOrder(id: string, limitNum: number) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    return this.httpClient.post<{ message: string; result: any }>(
      "https://rjzrjrv4vd.execute-api.ap-south-1.amazonaws.com/dev/api/admin/getpreviousorders/of/user/admin",
      { userid: id, limitNum },
      { headers }
    );
  }
  servicableDeliveryAreaWithProducts(coordinates: any) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    console.log(coordinates)
    return this.httpClient.post<{ message: string; data: any }>(
      "https://rjzrjrv4vd.execute-api.ap-south-1.amazonaws.com/dev/api/admin/area/in/service/with/products/and/qty",
      { coordinates },
      { headers }
    );
  }

  // package modify
  ModifyPackageQuantity(userid: string, quantity: any) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    console.log({ userid, quantity });
    return this.httpClient.post<{ message: string; result: any }>(
      "https://rjzrjrv4vd.execute-api.ap-south-1.amazonaws.com/dev/api/admin/user/modify/package/change/quantity/admin",
      { userid, quantity },
      { headers }
    );
  }
  // membership

  addMembership(userId: string) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    return this.httpClient.post<{ message: string; data: any }>(
      "https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/api/admin/add/membership/to/user",
      {
        userid: userId,
      },{headers}
    );
  }

  extendMembership(userId: string, month: number) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    return this.httpClient.post(
      "https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/extendUserMembership",
      {
        userid: userId,
        months: month,
      },
      {headers}
    );
  }

  // wallet

  getWalletSummaryOfUser(limitNum: number, userId: string) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    return this.httpClient.post<{
      message: string;
      result: [
        {
          BringJalWallet: string;
          amount: number;
          description: string;
          transaction_date: Date;
          transaction_type: { credit: boolean; debit: boolean };
          user: string;
          _id: string;
        }
      ];
    }>(
      "https://rjzrjrv4vd.execute-api.ap-south-1.amazonaws.com/dev/api/admin/get/wallet/summary/for/user/admin",
      {
        userid: userId,
        limitNum: limitNum,
      },
      { headers }
    );
  }
  getWalletAmountOfUser(userId: string) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    return this.httpClient.post<{
      message: string;
      result: any;
    }>(
      "https://rjzrjrv4vd.execute-api.ap-south-1.amazonaws.com/dev/api/admin/get/money/info/in/wallet/admin",
      {
        userid: userId,
      },
      { headers }
    );
  }
  refunMoneyTowallet(userId: string, amount: number, message: string) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    return this.httpClient.post(
      "https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/api/admin/refund/money/to/user/wallet",
      {
        userid: userId,
        amount: amount,
        message: message,
      },{headers}
    );
  }

  getUserDeposit(userId: string) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    return this.httpClient.post<{
      message: string;
      result: any;
    }>(
      "https://rjzrjrv4vd.execute-api.ap-south-1.amazonaws.com/dev/api/admin/user/get/deposits/data/admin",
      {
        userid: userId,
      },
      { headers }
    );
  }

  getUserDepositTransaction(userId: string) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    return this.httpClient.post<{
      message: string;
      data: any;
    }>(
      "https://rjzrjrv4vd.execute-api.ap-south-1.amazonaws.com/dev/api/admin/user/get/user/deposit/transactions/admin",
      {
        userid: userId,
      },
      { headers }
    );
  }

  // device

  attachDeviceToUser(userId: string, deviceID: string) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    return this.httpClient.post<{ message: string; result: any }>(
      `https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/api/installation/map/device/to/user/${userId}`,
      {
        droppyID: deviceID,
      },
      {headers}
    );
  }

  detachDeviceToUser(userId: string) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    return this.httpClient.post<{ message: string; result: any }>(
      `https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/api/admin/app/detach/device/from/user/${userId}`,
      {},
      {headers}
    );
  }

  // mobile edit
  mobileNumberEdit(mobile: string, userId: string) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    console.log(mobile, userId);
    return this.httpClient
      .post<{ message: string; result: any }>(
        `https://rjzrjrv4vd.execute-api.ap-south-1.amazonaws.com/dev/api/admin/edit/mobile/user`,
        { mobile: mobile, userid: userId },
        { headers }
      )
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }

  // address

  getUserAddress(userId: string) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    return this.httpClient
      .get<{ message: string; result: any }>(
        `https://rjzrjrv4vd.execute-api.ap-south-1.amazonaws.com/dev/api/admin/get/user/address/details/${userId}`,
        { headers }
      )
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }

  addUserAddress(
    userid: string,
    place: string,
    longitude: number,
    latitude: number,
    
  ) {
    console.log({
      place,
      
      longitude,
      latitude,
     
    });
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    return this.httpClient
      .post(
        `https://rjzrjrv4vd.execute-api.ap-south-1.amazonaws.com/dev/api/admin/add/address/to/user`,
        {
          userid,
          place,
          
          longitude,
          latitude,
        
        },
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }

  editUserAddress(
    userId: string,
    primary_address: string,
    secondary_address: string,
    lift: boolean,
    floor: number,
    longitude: number,
    latitude: number,
    landmark: string,
    delivery_note: string
  ) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    return this.httpClient
      .post(
        `https://rjzrjrv4vd.execute-api.ap-south-1.amazonaws.com/dev/api/admin/edit/address/of/user/${userId}`,
        {
          primary_address,
          secondary_address,
          lift,
          floor,
          longitude,
          latitude,
          landmark,
          delivery_note,
        },
        {headers}
      )
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }

  removeUserAddress(userid: string) {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    console.log(userid);
    return this.httpClient
      .post<{ message: string; result: any }>(
        `https://rjzrjrv4vd.execute-api.ap-south-1.amazonaws.com/dev/api/admin/remove/address/to/user`,
        { userid },
        { headers }
      )
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }
  addressOnMarkerChange(
    userId: string,
    latitude: number,
    longitude: number,
    primary_address: string
  ) {
    console.log(latitude, longitude, userId);
    var headers = new HttpHeaders({
      "Content-type": "application/json",
      token: this.token,
    });
    return this.httpClient.post<{ result: any }>(
      `https://rjzrjrv4vd.execute-api.ap-south-1.amazonaws.com/dev/api/admin/on/address/marker/change/of/user`,
      { coordinates: [longitude, latitude], userid: userId },
      { headers }
    );
  }

  // active order
  sendWhatsAppMessage(
    amount: number,
    order_id: string,
    userid: string,
    username: string,
    notes: string,
    mobile: string
  ) {
    const payload = {
      type: "Admin",
      amount,
      order_id,
      userid,
      username,
      notes,
      mobile,
    };
    const headers = new HttpHeaders({
      "Content-Type": "application/json", // Specify the content type
    });
    return this.httpClient
      .post(
        "https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/createAGeneralPayLink",
        payload,
        { headers }
      )
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }

  // ticket

  generateTicket(
    name: string,
    email: string,
    mobile: string,
    subject: string,
    description: string,
    images_array: any
  ) {
    const payload = {
      name,
      email,
      mobile,
      subject,
      description,
      images_array,
    };
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    return this.httpClient
      .post(
        "https://rjzrjrv4vd.execute-api.ap-south-1.amazonaws.com/dev/api/admin/generate/ticket/to/user",
        payload,
        { headers }
      )
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }

  uploadImageToGenerateTicket(formData: FormData) {
    var headers = new HttpHeaders({
      token: this.token,
    });
    console.log(formData);
    return this.httpClient.post<{ res: any }>(
      "https://rjzrjrv4vd.execute-api.ap-south-1.amazonaws.com/dev/api/admin/upload/image/generate/ticket/user",
      formData,
      { headers }
    );
  }
  //app constants

  getAllAppContants() {
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    return this.httpClient.get<{ result: any }>(
      "https://3x2rcmdq17.execute-api.ap-south-1.amazonaws.com/dev/api/admin/fetch/constants",
      { headers }
    );
  }
  editAppConstants(id: string, data: any) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      token: this.token,
    });
    console.log(id, data);
    return this.httpClient.post(
      `https://3x2rcmdq17.execute-api.ap-south-1.amazonaws.com/dev/api/admin/update/constants/${id}`,
      data,
      { headers }
    );
  }
}
