import { Component, Input, NgModule, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  ReactiveFormsModule,
} from "@angular/forms";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { UserBackupService } from "src/app/shared/service/user-backup.service";
import { SharedModule } from "src/app/shared/shared.module";
import { CustomerOrderComponent } from "../customer-order-track/customer-order.component";
import { MembershipCustomerComponent } from "../membership-customer/membership-customer.component";
import { WalletRefundSummaryComponent } from "../wallet-and-refund-summary/wallet-refund-summary.component";

@Component({
  selector: "app-search-mobile-customer",
  standalone: true,
  imports: [SharedModule, ReactiveFormsModule,CustomerOrderComponent,RouterLink,MembershipCustomerComponent,WalletRefundSummaryComponent],
  templateUrl: "./search-mobile-customer.component.html",
  styleUrl: "./search-mobile-customer.component.scss",
})
export class SearchMobileCustomerComponent implements OnInit {
  user:any=null
  backButtonShow:boolean=false
  loader:boolean=false
  @Input() id:string
  errorMessage:string|null=null
  constructor(
    private _UserBackupService: UserBackupService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {} 

  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next:(params) =>{
          this.id=params.get('id')
          
          if(this.id){
          this._UserBackupService.searchUserById(this.id).subscribe({
            next:(data) => {
             console.log("userbyid",data)
             this.user=data?.data
             this.backButtonShow=true
             this.loader=false
       console.log(this.user)
        this.errorMessage=null
           
            },
            error:(err)=>{console.log("userbyiderror",err?.error)
              this.errorMessage=err?.error?.message
              this.loader=false
              this.user=null        
            }
          });
        }
      },
    })
   
  }
  navigateEditMobile(){
    this.router.navigate(['/edit-mobile-number', this.user?._id])
  }
  navigateEditAddress(){
    this.router.navigate(['/edit-customer-address', this.user?._id])
  }

  searchCustomer(mobile:any) {
    this.loader=true;
    
    this._UserBackupService.searchUserByMobile(mobile.value).subscribe({
      next:(data)=>{
        this.user=data?.result
        this.backButtonShow=true
       
        mobile.value='';
        this.errorMessage=null
        this.loader=false
        // console.log(data)
    },
    error:(err)=>{
      console.log('search mobile customer',err)
      this.errorMessage=err?.error?.message
      this.user=null
      this.loader=false
    
      console.log(this.errorMessage)
      
      
    }
    })
  }
  backButtonClick(){
    this.user=null;
    this.errorMessage=null
    this.backButtonShow=false

  }
}
