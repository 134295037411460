<div class="container-fluid">
    <div class="card">
        <div class="card-body">
            <div class="row my-2">
                <div class="col-12 my-2">
                    <div class="input-group m-3">
                        <span class="input-group-text" id="basic-addon1">D000</span>
                        <input type="text" class="form-control" placeholder="Enter device id" aria-label="Username"
                            aria-describedby="basic-addon1">
                    </div>
                </div>
                <div class="col-12">
                    <div class="d-grid gap-2 col-6 mx-auto">
                        <button class="btn btn-secondary" type="button">Submit</button>
                    </div>
                </div>
                <div class="col-12 my-2">
                    <h5><b>Device Name:</b> D000123456789</h5>
                    <h5><b>Activation date:</b> 12/05/2024</h5>
                </div>
                <div class="col-12">
                    <div class="d-grid gap-2 col-6 mx-auto">
                        <button class="btn btn-secondary" type="button">Detach device</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>