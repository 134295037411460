@if(userId){
<div class="container-fluid" >
    <div class="row">
        @if(loader1){
            <div  class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        }@else{
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h1>Wallet Amount</h1>
                    </div>
                    <div class="card-body">
                       <h5>Bringjal Cash : {{total_amount_in_wallet}}</h5> 
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <h1>Refund Amount To Wallet</h1>
                    </div>
                    
                    <div class="card-body">
                        <form [formGroup]="issueRefundToWalletForm" (ngSubmit)="issueRefund()">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="minQtyInput">Amount</label>
                                        <input type="number" formControlName="amount" class="form-control" id="minQtyInput" aria-describedby="minQtyHelp" placeholder="Enter amount">
                                        <small *ngIf="!issueRefundToWalletForm.get('amount').valid && issueRefundToWalletForm.get('amount').touched" class="form-text text-muted red-text">
                                            <div *ngIf="issueRefundToWalletForm.get('amount').errors['required']">Amount is Required</div>
                                        </small>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group pt-2">
                                        <label for="minQtyInput">Description</label>
                                        <input type="text" formControlName="message" class="form-control" id="minQtyInput" aria-describedby="minQtyHelp" placeholder="Enter description">
                                        <small *ngIf="!issueRefundToWalletForm.get('message').valid && issueRefundToWalletForm.get('message').touched" class="form-text text-muted red-text">
                                            <div *ngIf="issueRefundToWalletForm.get('message').errors['required']">Description is Required</div>
                                        </small>
                                    </div>
                                </div>
                                <div class="col-12 pt-2">
                                    <button type="submit" [disabled]="issueRefundToWalletForm.invalid" class="btn btn-primary float-right">Refund money</button>
                                </div>
                            </div>
                        </form>
        
                    </div>
                </div>
            </div>
        }
   

    <div class="col-12" *ngIf="previousTransactions.length > 0">
        <div class="card" *ngIf="walletSummary">
            <div class="card-header">
                <h1>Previous Wallet Transactions</h1>
            </div>
            <div class="card-body">
                <div class="col-12">
                    <div class="list-group">
                        <a *ngFor="let order of previousTransactions; index as i"
                            class="list-group-item list-group-item-action" aria-current="true">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">{{order.transaction_date | relativedate}}</h5>
                                <small><span *ngIf="order.transaction_type.credit">Credit</span><span *ngIf="order.transaction_type.debit">Debit</span></small>
                            </div>
                            <hr>
                            <h4 class=" text-danger" [ngClass]="{'text-success' : order.transaction_type.credit}">Order Total:&nbsp;&nbsp;<span *ngIf="order.transaction_type.debit">-&nbsp;</span><span *ngIf="order.transaction_type.credit">+&nbsp;</span>&#8377;{{order.amount}}/-
                            </h4>
                            <p><small>{{order.description}}</small></p>
                            
                        </a>

                    </div>
                </div>
                @if(loader2){
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                }@else{
                    <div class="col-12 pt-2" *ngIf="showmoreButton">
                        <button type="button" (click)="showmore()" class="btn btn-primary btn-lg btn-block">Show More Transaction Summary</button>
                    </div>
                }
                
            </div>
        </div>
    
                
    </div>
    </div>
    </div>
}