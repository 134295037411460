<div class="container-fluid">
    <div class="card">
        @if(!editUserAddress){
        <div class="row m-3">
            <div class="col-6" *ngIf="apiLoaded | async">
                <google-map height="450px" width="100%" [center]="center" [zoom]="zoom">
                    <map-marker [position]="markerPosition" [options]="markerOptions"
                        >
                    </map-marker>
                </google-map>
            </div>
            <div class="col-6 p-3">
                <p><b>Primary Address:</b> NGEF Layout, Sadahana Nagar </p>
                <p><b>Secondary Address:</b> 370,1st cross, 7th main </p>
                <p><b>Landmark:</b> NGEF Layout, Sadahana Nagar </p>
                <p><b>Floor:</b>4</p>
                <p><b>Lift Available:</b></p>
                <div class="d-flex">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                        <label class="form-check-label" for="flexRadioDefault1">
                            Yes
                        </label>
                    </div>
                    <div class="form-check mx-3">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                            checked>
                        <label class="form-check-label" for="flexRadioDefault2">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-12 my-2">
                <div class="d-grid gap-2 col-6 mx-auto">
                    <button type="button" class="btn btn-secondary" (click)="editAddress()">Edit Address</button>
                </div>
            </div>
        </div>
        }
        @if(editUserAddress){
        <div class="row m-3">
            <div class="col-12">
                <app-search-bar (searchDataRecieved)="searchDataRecieved($event)"></app-search-bar>
                
              </div>
            <div class="col-12">
                <p class="text-center">Or</p>
            </div>
            <div class="col-md-4 col-6">
                <input type="text" class="form-control" placeholder="latitude" [(ngModel)]="latitude" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
            </div>
            <div class="col-md-4 col-6">
                <input type="text" class="form-control" placeholder="longitude" [(ngModel)]="longitude" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
            </div>
            <div class="d-grid gap-2 col-md-4 col-12 mx-auto">
                <button type="button" class="btn btn-outline-info" (click)="getLocation()">Get location</button>
            </div>
            <div class="col-12 my-3" *ngIf="apiLoaded | async">
                <google-map height="450px" width="100%" [center]="center" [zoom]="zoom">
                    <map-marker [position]="markerPosition" [options]="markerOptions"
                        (mapDragend)="markerDragEnd($event)">
                    </map-marker>
                </google-map>
            </div>
            <div class="col-12 my-3">
                <div class="col-12">
                    <h5><b>Primary Address:</b></h5>
                    <input type="text" readonly class="form-control-plaintext" id="floatingEmptyPlaintextInput"
                        placeholder="{{primaryAddress}}">
                </div>
                <div class="col-12">
                    <h5><b>Secondary Address:</b></h5>
                    <input type="text" class="form-control" placeholder="">
                </div>
                <div class="col-12">
                    <h5><b>Land mark:</b></h5>
                    <input type="text" class="form-control" placeholder="">
                </div>
                <div class="col-12">
                    <h5><b>Floor:</b></h5>
                    <input type="text" class="form-control" placeholder="">
                </div>
                <div class="col-12">
                    <h5><b>Lift Available:</b></h5>
                    <div class="d-flex">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                            <label class="form-check-label" for="flexRadioDefault1">
                                Yes
                            </label>
                        </div>
                        <div class="form-check mx-3">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                                checked>
                            <label class="form-check-label" for="flexRadioDefault2">
                                No
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 my-2">
                <div class="d-grid gap-2 col-6 mx-auto">
                    <button type="button" class="btn btn-secondary" (click)="updateAddress()">Update Address</button>
                </div>
            </div>
        </div>
        }
    </div>
</div>