import { NgIf } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router, RouterLink } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserBackupService } from 'src/app/shared/service/user-backup.service';

@Component({
  selector: 'app-edit-mobile-number',
  standalone:true,
  imports:[ReactiveFormsModule,RouterLink,NgIf],
  templateUrl: './edit-mobile-number.component.html',
  styleUrl: './edit-mobile-number.component.scss'
})
export class EditMobileNumberComponent implements OnInit {
  mobileForm: FormGroup;
  id:string
  errorMessage:string|null=null
  loader:boolean=false
  _UserBackupService=inject(UserBackupService) 
  route=inject(ActivatedRoute)
  toastr= inject(ToastrService)
  router=inject(Router) 
  
  ngOnInit(): void {

    this.mobileForm = new FormGroup({
      mobile: new FormControl(
        null,
        [Validators.required] 
      ), 
    });
    this.route.paramMap.subscribe((params: ParamMap) => { 
          this.id = params.get('id');
         
          this._UserBackupService.searchUserById(this.id).subscribe({
            next:(data) => {
             
             
             this.mobileForm.patchValue({mobile:data.data.mobile})
           
            },
            error:(err)=>{console.log("userbyiderror",err.error)
             
            }
          });
        });

  
  }
  

  mobileDevice(){
    this.loader=true
    this._UserBackupService.mobileNumberEdit(this.mobileForm.controls.mobile.value,this.id).subscribe({
      next:(data)=>{
        console.log(data)
        this.toastr.success('Mobile Edit Successfull');
        this.loader=false
        this.errorMessage=null
        // this.router.navigate(['customer-mobile'])

      },
      error:(err)=>{console.log(err.error)
        this.loader=false
        this.errorMessage="Unable to update mobile number"
      }
    })
  }

}
