import { inject, NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivateFn, Router } from '@angular/router';
import { content } from './shared/routes/content-routes';
import { ContentLayoutComponent } from './shared/layout/content-layout/content-layout.component';
import { LoginComponent } from './components/auth/login/login.component';
// import { authGuard, authGuardLoad } from './components/auth/auth.guard';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CookieService } from 'ngx-cookie-service';
import { GlobalContextService } from './shared/service/globalstorage.service';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard/default'
  },
  {
    path: '',
    component: ContentLayoutComponent,
    children: content,
  },
  {
    path: 'auth/login',
    component: LoginComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
