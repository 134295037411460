<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>User Details</h5>
        </div>
        <div class="card-body">

            <!-- <div id="batchDelete" class="category-table custom-datatable transcation-datatable user-image">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col" >Actions</th>
                                <th scope="col" sortable="avatar" (sort)="onSort($event)">Avtar</th>
                                <th scope="col" sortable="fName" (sort)="onSort($event)">First Name</th>
                                <th scope="col" sortable="lName" (sort)="onSort($event)">Last Name</th>
                                <th scope="col" sortable="email" (sort)="onSort($event)">Email</th>
                                <th scope="col" sortable="last_login" (sort)="onSort($event)">Last Login</th>
                                <th scope="col" sortable="role" (sort)="onSort($event)">Role</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of tableItem$ | async ">
                                <td>
                                    <a href="javascript:void(0)"><i class='fa fa-edit f-12'></i></a>&nbsp;
                                    <a href="javascript:void(0)"><i class="fa fa-trash-o"></i></a>
                                </td>
                                <td scope="row">
                                    <img [src]="item.avatar" class="imgTable" style="width: 20px">
                                </td>
                                <td>
                                    {{item.fName}}
                                </td>
                                <td>{{item.lName}}</td>
                                <td>{{item.email}}</td>
                                <td>{{item.last_login}}</td>
                                <td>{{item.role}}</td>
                            </tr>
                        </tbody>
                    </table> 
                    <div class="d-flex justify-content-center p-2">
                        <ngb-pagination
                          [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                        </ngb-pagination>
                    </div>
                </div>
            </div> -->
            <div class="row">
                <div class="col-md-6 col-12">
                    <div class="d-flex">
                        <p class="fw-bold">Name: </p><p>Ram</p>
                    </div>
                    <div class="d-flex">
                        <p class="fw-bold">Mobile Number: </p><p>9875185154</p>
                    </div>
                    <div class="d-flex">
                        <p class="fw-bold">Email: </p><p>ram&#64;gmail.com</p>
                    </div>
                    <div class="d-flex">
                        <p class="fw-bold">Referal Code: </p><p>EDN45NB</p>
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="d-grid gap-2">
                        <button class="btn btn-warning" type="button" routerLink="/users/edit-profile">Edit Profile</button>
                        <button class="btn btn-primary" type="button">Delete Profile</button>
                      </div> 
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="d-grid gap-3">
                <div class="row row-gap-3">
                    @if(initialOrder){
                        <div class="col-md-6 col-12">    
                            <div class="d-grid gap-2">
                                <button class="btn btn-primary" type="button" (click)="placeInitialOrder()">Place Initial Order</button>
                              </div>            
                        </div>
                    }
                    @if(!initialOrder){
                        <div class="col-md-6 col-12">    
                            <div class="d-grid gap-2">
                                <button class="btn btn-primary" type="button" routerLink="/users/place-order">Place and Track Order</button>
                              </div>            
                        </div>
                    }
                    
                    <div class="col-md-6 col-12">
                        <div class="d-grid gap-2">
                            <button class="btn btn-secondary " type="button" routerLink="/users/bringjal-wallet">Bringjal Cash</button>
                        </div>
                    </div>
                </div>
                  
                <div class="row  row-gap-3">
                    <div class="col-md-6 col-12">    
                        <div class="d-grid gap-2">
                            <button class="btn btn-danger" type="button" routerLink="/users/modify-quantity">Modify Quantity</button>
                          </div>            
                    </div>
                    <div class="col-md-6 col-12">
                        <div class="d-grid gap-2">
                            <button class="btn btn-warning" type="button" routerLink="/users/previous-orders">Previous Orders</button>
                        </div>
                    </div>
                </div>
                <div class="row  row-gap-3">
                    <div class="col-md-6 col-12">    
                        <div class="d-grid gap-2">
                            <button class="btn btn-info" type="button" routerLink="/users/refund">Refund</button>
                          </div>            
                    </div>
                    <div class="col-md-6 col-12">
                        <div class="d-grid gap-2">
                            <button class="btn btn-primary" type="button" routerLink="/users/membership">Membership</button>
                        </div>
                    </div>
                </div>
                <div class="row row-gap-3">
                    <div class="col-md-6 col-12">    
                        <div class="d-grid gap-2">
                            <button class="btn btn-warning" type="button" routerLink="/users/assign-device">Assign Device</button>
                          </div>            
                    </div>
                    <div class="col-md-6 col-12">
                        <div class="d-grid gap-2">
                            <button class="btn btn-secondary" type="button" routerLink="/users/customer-support">Customer Support</button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-12">    
                        <div class="d-grid gap-2">
                            <button class="btn btn-success" type="button" routerLink="/users/edit-user-address">Edit User Address</button>
                          </div>            
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->