import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customer-support',
  standalone: true,
  imports: [],
  templateUrl: './customer-support.component.html',
  styleUrl: './customer-support.component.scss'
})
export class CustomerSupportComponent {

  constructor(private router: Router,){

  }

  ngOninit() {

  }

  addNewTicket(){
    this.router.navigate(['/users/new-ticket']);
  }
}
