import { Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Observer } from 'rxjs';
import { ApiService, Maps } from '../../service/api.service';

const colors = [
  'red',
  'blue',
  'green',
  'yellow',
  'brown',
  'BurlyWood',
  'Cyan',
  'DarkGreen',
  'DarkOrchid',
  'DarkOliveGreen',
  'Fuchsia',
  'GoldenRod',
  'Indigo',
  'LightCoral',
  'MediumSlateBlue',
];
let colorIndex = 0;
 
const place = null as google.maps.places.PlaceResult;
type Components = typeof place.address_components;
@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
  dBtnFetchGps:boolean = false;
  @Output() searchDataRecieved = new EventEmitter < any > ();

  @ViewChild('search')
  public searchElementRef: ElementRef;
  searchControl: FormControl;

  gpsError: string;
  
  private map: google.maps.Map;

  constructor(apiService: ApiService, private ngZone: NgZone) {
    apiService.api.then((maps) => {
      this.initAutocomplete(maps);
    });
   }

  ngOnInit(): void {
    this.searchControl = new FormControl();
    
  }

  initAutocomplete(maps: Maps) {
    let autocomplete = new maps.places.Autocomplete(
      this.searchElementRef.nativeElement
    );
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        //console.log("place_changed", autocomplete.getPlace());
        this.onPlaceChange(autocomplete.getPlace());
      });
    });
  }

  getPosition(position): Observable < any >{
    return new Observable((observer: Observer < any > ) => {
      const coordinates = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      //console.log("coordinated before geocode:", coordinates);
      let geocoder = new google.maps.Geocoder;
      geocoder.geocode({
        'location': coordinates
      }, (results, status) => { 
        this.ngZone.run(() => {
          //console.log("address from coordinates:", results[0].formatted_address);
          var res = {
            coordinates: coordinates,
            searchAddr: results[0].formatted_address
          }
          observer.next(res);
          observer.complete();
        });
      });
    });
  }
 


  onPlaceChange(place: google.maps.places.PlaceResult) {
    this.searchControl.reset();
    const location = this.locationFromPlace(place);
    this.searchDataRecieved.emit(location);
  }


  public locationFromPlace(place: google.maps.places.PlaceResult) {
    const components = place.address_components;
    if (components === undefined) {
      return null;
    }
    console.log("place.name:", place.name);
    console.log("place.address_components[0]+', '+place.address_components[1]",place.address_components[0].long_name+', '+place.address_components[1].short_name);
    if(place.name === (place.address_components[0].long_name+', '+place.address_components[1].short_name)){
      const searchAddr = place.formatted_address;
      console.log("locationFromPlace:", place.address_components);
      console.log("formatted address:", searchAddr);
      const coordinates = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      return {
        coordinates,
        searchAddr
      };
    }else{
      const searchAddr = place.name + ", " + place.formatted_address;
      console.log("locationFromPlace:", place.address_components);
    console.log("formatted address:", searchAddr);
    const coordinates = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };
    return {
      coordinates,
      searchAddr
    };
    }
  }

}

    

    // const areaLevel3 = getShort(components, 'administrative_area_level_3');
    // const locality = getLong(components, 'locality');
 
    // const cityName = locality || areaLevel3;
    // const countryName = getLong(components, 'country');
    // const countryCode = getShort(components, 'country');
    // const stateCode = getShort(components, 'administrative_area_level_1');
    // const name = place.name !== cityName ? place.name : null;
 
    
 
    // const bounds = place.geometry.viewport.toJSON();
    // // placeId is in place.place_id, if needed
    // console.log("locationFromPlace before return,areaLevel3:", areaLevel3);
    // console.log("locationFromPlace before return,locality:", locality);
    // console.log("locationFromPlace before return,cityName:", cityName);
    // console.log("locationFromPlace before return,stateCode:", stateCode);
    // console.log("locationFromPlace before return,name:", name);
    // console.log("locationFromPlace before return,coordinates:", coordinates);
    // console.log("locationFromPlace before return,bounds:", bounds);
// function getComponent(components: Components, name: string) {
//   return components.filter((component) => component.types[0] === name)[0];
// }
 
// function getLong(components: Components, name: string) {
//   const component = getComponent(components, name);
//   return component && component.long_name;
// }
 
// function getShort(components: Components, name: string) {
//   const component = getComponent(components, name);
//   return component && component.short_name;
// }
