import { Component, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AdminService } from 'src/app/shared/service/admin.service';
import { GlobalContextService } from 'src/app/shared/service/globalstorage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers:[CookieService]
})
export class LoginComponent implements OnInit {
  cookieService=inject(CookieService)
  public loginForm: FormGroup;
  public registerForm: FormGroup;
  public active = 1;
  loginError:string|null=null
  registrationError:string|null=null

  constructor(private adminService: AdminService,
    private router: Router,
    private globalContextService:GlobalContextService
  ) {
    
  }

  ngOnInit() {  
    this.loginForm = new FormGroup({
      'mobile': new FormControl(null,[Validators.required, Validators.pattern('^[4|5|6|7|8|9][0-9]{9}$')]),
      'password': new FormControl(null, Validators.required),
    })
    this.registerForm = new FormGroup({
      'name':new FormControl('',Validators.required),
      'email':new FormControl('',[Validators.required,Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]),
      'mobile': new FormControl('',[Validators.required, Validators.pattern('^[4|5|6|7|8|9][0-9]{9}$')]),
      'password': new FormControl('', Validators.required),
    })
    this.globalContextService.getUser.subscribe({
  next:(user) =>{
      if(user?.userToken && user?.userRole){
        this.router.navigate(['/dashboard/default']);
      }
  },
    })
  }

  owlcarousel = [
    {
      title: "Welcome to Bringjal Admin",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    },
    {
      title: "Welcome to Bringjal Admin",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    },
    {
      title: "Welcome to Bringjal Admin",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    }
  ]
  owlcarouselOptions = {
    loop: true,
    items: 1,
    dots: true
  };

  // createLoginForm() {
  //   this.loginForm = this.formBuilder.group({
  //     userName: [''],
  //     password: [''],
  //   })
  // }
  
  onSubmit() {
    if(this.loginForm.valid){
    this.adminService.adminLogin(this.loginForm.value.mobile, this.loginForm.value.password).subscribe({
      next: (data:any)=>{
        
        // const expiryDate = new Date();
        // expiryDate.setDate(expiryDate.getDate() + 7);
        // this.cookieService.set('token', data.token,expiryDate);
        const roleData=data.Admindata.access;
        let role;
        if(roleData.admin){
          // this.cookieService.set('role', 'admin');
         role='admin'
        }
        if(roleData.superAdmin){
          // this.cookieService.set('role', 'superAdmin');
          role='superAdmin'
        }
        if(roleData.manager){
          // this.cookieService.set('role', 'manager');
          role='manager'
        }
        if(roleData.customerSupport){
          // this.cookieService.set('role', 'customerSupport');
          role='customerSupport'
        }
        this.globalContextService.setuserContext(data.token,role).subscribe({next:(value)=> {
            
        },})
        this.loginForm.reset();
        this.loginError=null
        this.router.navigate(['/dashboard/default']);
        
      },
      error: (error:any)=>{
        console.log(error);
        this.loginError=error.error
      }
    })
  }
  }


  onRegister(){
    
    if(this.registerForm.valid){
      // this.adminService.adminSignUp(this.registerForm.value.name,this.registerForm.value.email,this.registerForm.value.mobile,this.registerForm.value.password).subscribe({
      //   next: (data:any)=>{
      //     console.log(data);
      //     this.router.navigate(['/dashboard/default']);
      //     this.registerForm.reset();
      //     this.registrationError=null
      //   },
      //   error: (error:any)=>{
      //     console.log(error.error);
      //     this.registrationError=error.error.message
      //   }
      // })
    }
    }
}


