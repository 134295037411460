<div class="container-fluid">
  @if(loader){
    <div  class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                </div>
            </div>
        </div>
    </div>
}@else{
  <div class="row">
    @if(!membershipId ){
    <div class="col-12 pt-4">
      <div class="card">
      <div class="card-body">
      <button type="button" (click)="addMembership()" class="btn btn-primary">
        Add Membership
      </button>
      </div>
      </div>
    </div>
    }@else{
    <div class="col-12 pt-4">
      <div class="card">
        <div class="card-header">
          <h1>Membership</h1>
      </div>
      <div class="card-body">
        
      <input
        type="number"
        class="form-control no-left-border"
        aria-describedby="basic-addon2"
        name="month"
        placeholder="please enter month"
        id="month"
        #month="ngModel"
    [(ngModel)]="monthValue"
      />
      <button
        type="button"
        (click)="extendMembership(monthValue)"
        class="btn btn-primary mt-2"
        [disabled]="!monthValue || monthValue <= 0"
      >
        Extend Membership
      </button>
      </div>
      </div>
    </div>
    }
  </div>
}
</div>
