import { inject } from '@angular/core';
import { CanActivateFn, CanLoad, CanMatchFn, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { GlobalContextService } from 'src/app/shared/service/globalstorage.service';

export const authGuard: CanActivateFn = (route, state) => {
  const cookieService = inject(CookieService);
  const globalContextService=inject(GlobalContextService)
  const router = inject(Router);
  let result;
  globalContextService.getUser.subscribe({
    next:(user) =>{
      console.log(user)
        if(user?.userToken){
         
          result=true
        }
    },
  })
  if(result) return true
  
  router.navigate(['/auth/login'])
    return false;
  
};

export const authGuardLoad: CanMatchFn = (route, state) => {
  const router = inject(Router);
    const globalContextService=inject(GlobalContextService)
    let result
    globalContextService.getUser.subscribe({
      next:(user) =>{
        console.log(user)
          if(user.userToken){
          
            result=true
          }
      },
    })
     if(result) return true
  
 
    router.navigate(['/auth/login'])
    return false;
  
};

export const authRoleGuard: CanActivateFn = (route, state) => {
  const cookieService = inject(CookieService);
 
    const role = cookieService.get('role');
    if (role==='admin'||role==='superAdmin') return true;
    return false;
  
};
