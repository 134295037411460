import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { AgGridModule } from '@ag-grid-community/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { DashboardModule } from './components/dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';
import { ProductsModule } from './components/products/products.module';
import { SalesModule } from './components/sales/sales.module';
import { CouponsModule } from './components/coupons/coupons.module';
import { PagesModule } from './components/pages/pages.module';
import { MediaModule } from './components/media/media.module';
import { MenusModule } from './components/menus/menus.module';
import { VendorsModule } from './components/vendors/vendors.module';
import { UsersModule } from './components/users/users.module';
import { LocalizationModule } from './components/localization/localization.module';
import { InvoiceModule } from './components/invoice/invoice.module';
import { SettingModule } from './components/setting/setting.module';;
import { ReportsModule } from './components/reports/reports.module';
import { AuthModule } from './components/auth/auth.module';
import { GlobalContextService } from './shared/service/globalstorage.service';
import { GlobalHttpService } from './shared/service/globalhttpservice.service';
import { globalErrorHandler } from './shared/service/error-handler';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TrackingCodeComponent } from './main/tracking-code/tracking-code.component';
import { InventoriesComponent } from './main/inventories/inventories.component';
import { InventoryFormComponent } from './main/inventories/inventory-form/inventory-form.component';
import { InterestedUsersComponent } from './main/interested-users/interested-users.component';
import { SearchMobileCustomerComponent } from './main/customer-new/search-mobile-customer/search-mobile-customer.component';
import {CookieService} from 'ngx-cookie-service';
import { LoaderComponent } from './loader/loader.component';
import { LoaderInterceptor } from './loader/loader.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    InventoryFormComponent,
    TrackingCodeComponent,
    InventoriesComponent,
    InterestedUsersComponent,
    LoaderComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    DashboardModule,
    InvoiceModule,
    ReactiveFormsModule,
    SettingModule,
    ReportsModule,
    AuthModule,
    SharedModule,
    LocalizationModule,
    ProductsModule,
    SalesModule,
    VendorsModule,
    CouponsModule,
    PagesModule,
    MediaModule,
    MenusModule,
    UsersModule,
    AgGridModule,
    SearchMobileCustomerComponent
  ],
  providers: [GlobalContextService, GlobalHttpService,CookieService,
    { provide: ErrorHandler, useClass: globalErrorHandler },{provide:HTTP_INTERCEPTORS,useClass:LoaderInterceptor,multi:true}],  
  bootstrap: [AppComponent]
})
export class AppModule { }
