import { HttpClient } from '@angular/common/http';
import { Component, NgZone, ViewChild } from '@angular/core';
import { GoogleMap,  MapInfoWindow, MapMarker } from '@angular/google-maps';
import { catchError, map, Observable, Observer, of } from 'rxjs';

@Component({
  selector: 'app-edit-user-address',
  templateUrl: './edit-user-address.component.html',
  styleUrl: './edit-user-address.component.scss'
})
export class EditUserAddressComponent {
  editUserAddress: boolean = false;

  searchAddress: string;
  latitude: number;
  longitude: number; 
  addr: string;
   primaryAddress: any;
  place: any;
  apiLoaded: Observable<boolean>;

  @ViewChild(GoogleMap, {
    static: false
  }) set map(m: GoogleMap) {
    if (m) {
    }
  }

  options: google.maps.MapOptions = {
    zoom: 15
    //disableDefaultUI: true
  };

  markerOptions : google.maps.MarkerOptions = {
    draggable: true
  }
  center: google.maps.LatLngLiteral = {
    lat: 12.977717,
    lng: 77.676492
  };

  markerPosition: google.maps.LatLngLiteral = {
    lat: 12.977717,
    lng: 77.676492
  };
  latLng: google.maps.LatLngLiteral;

  constructor(private httpClient: HttpClient,
    private ngZone: NgZone
  ) {
    setTimeout(() => {
      this.apiLoaded = httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyC2kDhE4vTqxKvhe4OBOZLpjlptfB5M_zU&libraries=drawing', 'callback').pipe(map(() => true), catchError(() => of(false)));

    }, 2000);
    
   }

  ngOnInit(): void {

  }

  // center: google.maps.LatLngLiteral = { lat: 24, lng: 12 };
  zoom = 14;
  
  getLocation() {
    const coordinates = {
      lat: +this.latitude,
      lng: +this.longitude
    };
    this.markerPosition = coordinates;
    this.center = coordinates;
    // console.log('coordinates from get location',coordinates);
    this.getPosition(coordinates).subscribe((data: any)=>{
      console.log('coordinates from get location method',data);
    })
  }

  markerDragEnd(event: google.maps.MapMouseEvent) {
    console.log('Marker drag end', event.latLng.toJSON());

    this.getPosition(event.latLng.toJSON()).subscribe((data: any)=>{
      console.log(data);
    })
      
    
  }
  
 
  editAddress(){
    this.editUserAddress = true;
  }

  updateAddress(){
    this.editUserAddress = false;
  }
  searchDataRecieved(data) {
    console.log("data recieved from child component:", data);
    this.primaryAddress = data.searchAddr;
    this.searchAddress = data.searchAddress;
    this.latLng = data.coordinates;
    this.latitude = data.coordinates.lat;
    this.longitude = data.coordinates.lng;
    this.center = {
      lat: this.latitude,
      lng: this.longitude
    };
    this.markerPosition = {
      lat: this.latitude,
      lng: this.longitude
    };
  }
  
  getPosition(position): Observable < any >{
    console.log('coordinates from getPostion method',position);
    return new Observable((observer: Observer < any > ) => {
      const coordinates = {
        lat: position.lat,
        lng: position.lng,
      };
      console.log("coordinated before geocode:", coordinates);
      let geocoder = new google.maps.Geocoder;
      geocoder.geocode({
        'location': coordinates
      }, (results, status) => { 
        this.ngZone.run(() => {
          console.log("address from coordinates:", results[0].formatted_address);
          var res = {
            coordinates: coordinates,
            searchAddr: results[0].formatted_address
          }
          this.primaryAddress = res.searchAddr;
          console.log('coordinates name',this.primaryAddress);

          observer.next(res);
          observer.complete();
        });
      });
    });
  }
}
