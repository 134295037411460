import { Component } from '@angular/core';

@Component({
  selector: 'app-bringjal-wallet',
  standalone: true,
  imports: [],
  templateUrl: './bringjal-wallet.component.html',
  styleUrl: './bringjal-wallet.component.scss'
})
export class BringjalWalletComponent {
  walletAmount: number = 100 ;
}
