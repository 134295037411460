import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { flash } from 'ng-animate';
import { ToastrService } from 'ngx-toastr';
import { UserBackupService } from 'src/app/shared/service/user-backup.service';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-wallet-refund-summary',
  standalone: true,
  imports: [NgIf,NgFor,NgClass,SharedModule,ReactiveFormsModule],
  templateUrl: './wallet-refund-summary.component.html',
  styleUrl: './wallet-refund-summary.component.scss'
})
export class WalletRefundSummaryComponent {
  issueRefundToWalletForm: FormGroup;
  previousTransactions = [];
  total_amount_in_wallet:number
  prevTransactionLength: number;
  walletSummary: boolean;
  showmoreButton:boolean=true
  loader1:boolean=false
  loader2:boolean=false
  errorMessage:string|null=null
  @Input({required:true}) userId:string

  constructor(private _UserBackupService : UserBackupService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    
     this.prevTransactionLength = 0;
    this.issueRefundToWalletForm = new FormGroup ({
      amount: new FormControl(null , Validators.required),
      message: new FormControl(null , Validators.required)
    });
    if(this?.userId){
    this._UserBackupService.getWalletAmountOfUser(this.userId).subscribe({
      next:(data)=>{
        console.log("get wallet amount",data);
        this.total_amount_in_wallet=data.result.total_amount_in_wallet
  },
  error:(err) =>console.log(err)
    })

    this._UserBackupService.getWalletSummaryOfUser(0, this.userId).subscribe({
      next:(data)=>{
            console.log("get wallet summary",data);
      this.walletSummary = true;
      var transactions = data.result;
      if(transactions.length<2){
        this.showmoreButton=false
      }
      this.prevTransactionLength+=transactions.length;
      transactions.forEach(element => {
      this.previousTransactions.push(element) ;
    })
      },
      error:(err) =>console.log(err)
     })
    }
  }

  issueRefund(){

 this.loader1=true
     this._UserBackupService.refunMoneyTowallet(this.userId, this.issueRefundToWalletForm.value.amount, this.issueRefundToWalletForm.value.message).subscribe({
      next:(resData)=>{
        console.log(resData),
             this.toastr.success('Hurray', 'Money transaction completed in your wallet');
             this.loader1=false
             this.errorMessage=null
             this.issueRefundToWalletForm.reset()
      },
      error:(err) =>{console.log(err)
        this.loader1=false
        this.errorMessage="Unable to refund amount"
      }

  });
}

  showmore(){
    this.loader2=true
     this._UserBackupService.getWalletSummaryOfUser(this.prevTransactionLength+2, this.userId).subscribe({
      next:(data)=>{
            console.log("get wallet summary",data);
      this.walletSummary = true;
      var transactions = data.result;
      if(transactions.length<2){
        this.showmoreButton=false
      }
      this.prevTransactionLength+=transactions.length;
      transactions.forEach(element => {
      this.previousTransactions.push(element) ;
    });
    this.loader2=false
      },
      error:(err) =>{console.log(err)
        this.loader2=false
      }
     });
}
}
