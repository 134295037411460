import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-ticket',
  standalone: true,
  imports: [],
  templateUrl: './new-ticket.component.html',
  styleUrl: './new-ticket.component.scss'
})
export class NewTicketComponent {
  
  constructor(private router: Router,){

  }

  ngOninit() {

  }

  submit(){
    this.router.navigate(['/users/customer-support']);
  }
}
