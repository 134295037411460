<div class="container-fluid">
    <div class="d-grid gap-2 my-3 d-md-flex justify-content-md-end">
        <button class="btn btn-secondary" type="button" (click)="addNewTicket()">Add New Ticket</button>
      </div>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <h5>Water Can Issue</h5>
                        <p>Watercan was little damaged on the bottom edge</p>
                    </div>
                    <div class="col-12">
                        <p class="float-start"><span class="fw-semibold">Ticket Status:</span>Open</p>
                        <p class="float-end"><span class="fw-semibold">Date Created:</span> 12/01/2022</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <h5>Water Can Issue</h5>
                        <p>Watercan was little damaged on the bottom edge</p>
                    </div>
                    <div class="col-12">
                        <p class="float-start"><span class="fw-semibold">Ticket Status:</span>Closed</p>
                        <p class="float-end"><span class="fw-semibold">Date Created:</span> 12/01/2022</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <h5>Water Can Issue</h5>
                        <p>Watercan was little damaged on the bottom edge</p>
                    </div>
                    <div class="col-12">
                        <p class="float-start"><span class="fw-semibold">Ticket Status:</span>close</p>
                        <p class="float-end"><span class="fw-semibold">Date Created:</span> 12/01/2022</p>
                    </div>
                </div>
            </div>
        </div>
</div>