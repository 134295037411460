import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'products',
    loadChildren: () => import('../../main/products/products.module').then(m => m.ProductsModule),
    data: {
      title: "Products",
      breadcrumb: "Products"
    }
  },
  {
    path: 'membership-packages',
    loadChildren: () => import('../../main/membership-packages/membership-packages.module').then(m => m.MembershipPackagesModule),
    data: {
      title: "Membership Packages",
      breadcrumb: "Membership Packages"
    }
  },
  {
    path: 'app-constants',
    loadChildren: () => import('../../main/app-constants/app-constants.module').then(m => m.AppConstantsModule),
    data: {
      title: "App Constants",
      breadcrumb: "App Constants"
    }
  },
  {
    path: 'delivery-area',
    loadChildren: () => import('../../main/delivery-area/delivery-area.module').then(m => m.DeliveryAreaModule),
    data: {
      title: "Delivery Area",
      breadcrumb: "Delivery Area"
    }
  },
  {
    path: 'coupons',
    loadChildren: () => import('../../main/coupons/coupons.module').then(m => m.CouponsModule),
    data: {
      title: "Coupons",
      breadcrumb: "Coupons"
    }
  },
  {
    path: 'tax-rules', 
    loadChildren: () => import('../../main/tax-rules/tax-rules.module').then(m => m.TaxRulesModule),
    data: {
      title: "Tax Rules",
      breadcrumb: "Tax Rules"
    }
  },
  {
    path: 'water-cans',
    loadChildren: () => import('../../main/watercans/watercans.module').then(m => m.WatercansModule),
    data: {
      title: "Water cans",
      breadcrumb: "Water cans"
    }
  },
  {
    path: 'payments',
    loadChildren: () => import('../../main/payments/payments.module').then(m => m.PaymentsModule),
    data: {
      title: "Payments",
      breadcrumb: "Payments"
    }
  },
  {
    path: 'orders',
    loadChildren: () => import('../../main/orders/orders.module').then(m => m.OrdersModule),
    data: {
      title: "Orders",
      breadcrumb: "Orders"
    }
  },

  {
    path: 'admin-staff',
    loadChildren: () => import('../../main/admin-staff/admin-staff.module').then(m => m.AdminStaffModule),
    data: {
      title: "Admin Staff",
      breadcrumb: "Admin Staff"
    }
  },
  {
    path: 'vehicles',
    loadChildren: () => import('../../main/vehicle/vehicle.module').then(m => m.VehicleModule),
    data: {
      title: "Vehicles",
      breadcrumb: "Vehicles"
    }
  },

  {
    path: 'accessory',
    loadChildren: () => import('../../main/accessory/accessory.module').then(m => m.AccessoryModule),
    data: {
      title: "Accessory",
      breadcrumb: "Accessory"
    }
  },
  // {
  //   path: 'products',
  //   loadChildren: () => import('../../components/products/products.module').then(m => m.ProductsModule),
  //   data: {
  //     breadcrumb: "Products"
  //   }
  // },
  // {
  //   path: 'sales',
  //   loadChildren: () => import('../../components/sales/sales.module').then(m => m.SalesModule),
  //   data: {
  //     breadcrumb: "Sales"
  //   }
  // },
  // {
  //   path: 'coupons',
  //   loadChildren: () => import('../../components/coupons/coupons.module').then(m => m.CouponsModule),
  //   data: {
  //     breadcrumb: "Coupons"
  //   }
  // },
  // {
  //   path: 'pages',
  //   loadChildren: () => import('../../components/pages/pages.module').then(m => m.PagesModule),
  //   data: {
  //     breadcrumb: "Pages"
  //   }
  // },
  // {
  //   path: 'media',
  //   loadChildren: () => import('../../components/media/media.module').then(m => m.MediaModule),
  // },
  // {
  //   path: 'menus',
  //   loadChildren: () => import('../../components/menus/menus.module').then(m => m.MenusModule),
  //   data: {
  //     breadcrumb: "Menus"
  //   }
  //},
  {
    path: 'users',
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule),
    data: {
      breadcrumb: "Users"
    }
  },
  // {
  //   path: 'vendors',
  //   loadChildren: () => import('../../components/vendors/vendors.module').then(m => m.VendorsModule),
  //   data: {
  //     breadcrumb: "Vendors"
  //   }
  // },
  // {
  //   path: 'localization',
  //   loadChildren: () => import('../../components/localization/localization.module').then(m => m.LocalizationModule),
  //   data: {
  //     breadcrumb: "Localization"
  //   }
  // },
  // {
  //   path: 'reports',
  //   loadChildren: () => import('../../components/reports/reports.module').then(m => m.ReportsModule),
  // },
  // {
  //   path: 'settings',
  //   loadChildren: () => import('../../components/setting/setting.module').then(m => m.SettingModule),
  //   data: {
  //     breadcrumb: "Settings"
  //   }
  // },
  // {
  //   path: 'invoice',
  //   loadChildren: () => import('../../components/invoice/invoice.module').then(m => m.InvoiceModule),
  //   data: {
  //     breadcrumb: "Invoice"
  //   }
  // },
  {
    path: 'past-orders-on-map',
    loadChildren: () => import('../../main/past-orders-on-map/past-orders-on-map.module').then(m => m.PastOrdersOnMapModule),
    data: {
      title: "Past Orders On Map",
      breadcrumb: "Past Orders On Map"
    }
  },
  {
    path: 'active-orders-on-map',
    loadChildren: () => import('../../main/active-orders/active-orders.module').then(m => m.ActiveOrdersModule),
    data: {
      title: "Active Orders On Map",
      breadcrumb: "Active Orders On Map"
    }
  },
];