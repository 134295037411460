import { Component } from '@angular/core';

@Component({
  selector: 'app-place-and-track-order',
  standalone: true,
  imports: [],
  templateUrl: './place-and-track-order.component.html',
  styleUrl: './place-and-track-order.component.scss'
})
export class PlaceAndTrackOrderComponent {
  orderPlaced: boolean = false;

  constructor(){

  }

  ngOnInit() {

  }

  placeOrder(){
    this.orderPlaced = true;
  }
}
