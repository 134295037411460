<div class="container-fluid">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <h1>Add Bringjal Cash</h1>
                </div>
                <div class="col-12">
                    <h3>Bringjal Wallet Cash:&#8377; {{walletAmount}}</h3>
                </div>
                <div class="col-12">
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="floatingInput" placeholder="Enter amount">
                        <label for="floatingInput">Enter Amount You Want To Add To Wallet</label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="d-grid gap-2 d-md-flex">
                        <button class="btn btn-outline-secondary me-md-2" type="button">&#8377;100/-</button>
                        <button class="btn btn-outline-secondary" type="button">&#8377; 500/-</button>
                    </div>
                </div>
                <div class="col-12">
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <button class="btn btn-success me-md-2" type="button">Submit<i class="bi bi-wallet2"></i></button>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>