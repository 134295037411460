<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Place And Tarck The Order</h5>
        </div>
        <div class="card-body">
            @if(!orderPlaced){
                <div class="row">
                    <div class="col-12">
                        <h5><b>Bringjal Cash:</b>&#8377; 2550</h5>
                    </div>
                    <div class="col-12">
                        <h5><b>Quality:</b> 5</h5>
                    </div>
                    <div class="col-12">
                        <h5><b>Total amount to be paid:</b> 232</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="d-grid gap-2 col-6 mx-auto">
                            <button type="button" class="btn button" (click)="placeOrder()">
                                <div class="button-top">Place order</div>
                                <div class="button-bottom"></div>
                                <div class="button-base"></div>
                            </button>
                        </div>
                    </div>
                </div>
            }
            
            @if(orderPlaced){
                <div class="row">
                    <div class="col-md-6">
                        <h5><b>Order Id:</b> #JKFSDKF</h5>
                        <h5><b>Order placed</b> 12/07/2024</h5>
                    </div>
                    <div class="col-md-6 col-12" >
                        <h5><b>Total quantity:</b> 5</h5>
                      </div>
                      
                      <div class="col-md-6 col-12">
                        <h5><b>Order Total:</b> &#8377; 563</h5>
                      </div>
                      <div class="col-12">
                          <hr>
                      </div>
                      <div class="col-12">
                        <div class="row">
                          <div class="col-md-6 col-12">
                            <h5><b>Total Amount Cleared:</b> &#8377; 270</h5>
                          </div>
                          <div class="col-md-6 col-12">
                            <h5><b>Amount to be Paid:</b> &#8377; 123</h5>
                          </div>
                         
                            <div class="col-12 col-md-6">
                              <small class="grey-text">&#8377;123 paid through paymentLink</small>
                            </div>
                          
                        </div>
                      </div>
                      <div class="col-12">
                        <hr>
                      </div>                   
                        <div class="col-12">
                            <dl class="row">
                              <dd class="col-lg-9 col-8">
                                <b>4 X Watercans</b>
                                <!-- <small class="grey-text" *ngIf="item.item_total_deposit > 0">&nbsp;(item Deposit: &#8377;{{item.item_total_deposit}})</small> -->
                              </dd>
                              <dd class="col-lg-3 col-4">
                                &#8377; 543
                              </dd>
                              <div class="col-12">
                                <small>Refundable Deposit: &#8377; 233</small>
                              </div>
                              <div class="col-12">
                                <small>GST: &#8377; 23 + 32 + 43 </small>
                              </div>
                              <div class="col-12">
                               <small>Delivery Charge: &#8377; 45 <span>(including &#8377; 32 GST)</span></small>
                              </div>
                            </dl>
                          
                          <!-- <hr> -->
                        </div>
                        <div class="col-12">
                          <hr>
                        </div>
                        <div class="col-12">
                          <b>Prime</b> at &#8377; 210
                        </div>
                        <div class="col-12">
                          <hr>
                        </div>
                        <div class="col-12">
                          <dl class="row">
                              <dt class="col-12">
                                <small>Membership Discount: &#8377; 70</small>
                              </dt>
                              <dt class="col-12">
                                <small>Cart Discount: &#8377; 30</small>
                              </dt>

                              <dt class="col-12">
                                <small>AE44TDG45</small>
                              </dt>

                            
                              <dt class="col-12">
                                <small><b class="grey-text">Total Discount: &#8377; 150</b></small>
                              </dt>
                            
                          </dl>
                        </div>
                      
                      <div class="col-12">
                        <hr>
                      </div>
                      <div class="col-12">
                        <dl class="row"> 
                            <dt class="col-sm-12">
                              <b>Total</b>&nbsp;<b>&#8377; 432</b><span> (&#8377;458 Fully refundable deposit)</span>
                            </dt>
                          </dl>
                      </div>
                </div>
            }
        </div>
    </div>
</div>