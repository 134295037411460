import { inject, Injectable } from "@angular/core";

import { CookieStorageService } from "./cookie-storage.service";
import { BehaviorSubject, Observable, Observer } from "rxjs";
//let CookieOptionsVar = {domain: 'bringjal.com' } as CookieOptions;
import { JwtHelperService } from "@auth0/angular-jwt";
import { CookieService } from "ngx-cookie-service";
const helper = new JwtHelperService();

@Injectable({
  providedIn: "root",
})
export class GlobalContextService {
  private userSubject = new BehaviorSubject<any>(null);
  public getUser = this.userSubject.asObservable();

  private tokenSubject = new BehaviorSubject<any>(null);
  public getTokenData = this.tokenSubject.asObservable();


  private userLoggedIn = new BehaviorSubject<boolean>(null);
  public getLoggedIn = this.userLoggedIn.asObservable();
  constructor(private cookieStorage: CookieStorageService) {
    this.getUserContext().subscribe((user) => {});
    
  }

  userData: any;

  setuserContext(token): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      console.log("this.setuserContext")
      this.cookieStorage.set("token", token);
      let decoded = helper.decodeToken(token);
      console.log("decoded token data..", decoded);
      this.cookieStorage.set("userData", decoded.user);
      var user = {
        userToken: token,
        userData: decoded.user,
      };
      this.userSubject.next(user);
      this.userLoggedIn.next(true);
      observer.next(user);
      observer.complete();
    });
  }


  getUserContext(): Observable<any> {
    return new Observable((obsever: Observer<any>) => {
      var user = {
        userToken: this.cookieStorage.get("token"),
        userData: this.cookieStorage.get("userData"),
      };
      this.userSubject.next(user);
      this.userLoggedIn.next(true);
      obsever.next(user);
      obsever.complete();
    });
  }

  removeUserConext(): Observable<any> {
    return new Observable((obsever: Observer<any>) => {
      this.cookieStorage.remove("token");
      this.cookieStorage.remove("userData");
      this.cookieStorage.removeAll()
      this.userSubject.next(null);
      this.userLoggedIn.next(false);

      obsever.next(null);
      obsever.complete();
    });
  }
}
