import {
  Component,
  ElementRef,
  inject,
  NgZone,
  OnInit,
  ViewChild,
} from "@angular/core";
import { GoogleMap, MapInfoWindow, MapMarker } from "@angular/google-maps";
import { Observable, catchError, map, of } from "rxjs";
import {
  HttpClient,
  HttpClientJsonpModule,
  HttpClientModule,
  HttpHeaders,
} from "@angular/common/http";
import { UserBackupService } from "src/app/shared/service/user-backup.service";
import { CommonModule } from "@angular/common";
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedModule } from "src/app/shared/shared.module";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { ToastrService } from "ngx-toastr";
@Component({
  selector: "app-edit-address",
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    SharedModule,
    HttpClientModule,
    HttpClientJsonpModule,
    RouterLink
  ],
  templateUrl: "./edit-address.component.html",
  styleUrl: "./edit-address.component.scss",
})
export class EditAddressComponent implements OnInit {
  addr: string;
  loader:boolean=false
  place: any;
  errorMessage:string|null=null
  @ViewChild(GoogleMap, {
    static: false,
  })
  set map(m: GoogleMap) {
    if (m) {
    }
  }
  @ViewChild("search")
  public searchElementRef: ElementRef;
  apiLoaded: Observable<boolean>;
  user_Id: any;
  searchAddress: string;
  latitude: number;
  longitude: number;
  options: google.maps.MapOptions = {
    zoom: 15,
    //disableDefaultUI: true
  };
  center: google.maps.LatLngLiteral = {
    lat: 12.977717,
    lng: 77.676492,
  };
  markerPosition: google.maps.LatLngLiteral;
  latLng: google.maps.LatLngLiteral;

  markerOptionsAddress: google.maps.MarkerOptions = {
    draggable: true,
    label: {
      color: "red",
      text: "1",
    },
  };
  editAddressForm = new FormGroup({
    primary_address: new FormControl("", Validators.required),
    secondary_address: new FormControl("",Validators.required),
    landmark: new FormControl(""),
    floor: new FormControl<number>(0),
    lift: new FormControl(false, Validators.required),
  });
  liftStatus = ["Yes", "No"];
  delivery_note: string = "customer address edited";
    router=inject(Router)   
  constructor(
    private _UserBackupService: UserBackupService,
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private toastr: ToastrService, 
  ) {
    setTimeout(() => {
      this.apiLoaded = httpClient
        .jsonp(
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyC2kDhE4vTqxKvhe4OBOZLpjlptfB5M_zU&libraries=drawing",
          "callback"
        )
        .pipe(
          map(() => true),
          catchError(() => of(false))
        );
    }, 5000);
    httpClient.get;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (data) => {
        const id = data.get("id");
        this.user_Id=id
        if (id) {
          this._UserBackupService.getUserAddress(id).subscribe({
            next: (data) => {
              console.log(data)
              this.searchAddress = data.result.primary_address;
              
              const lat = data.result.latitude;
              const lng = data.result.longitude;
              this.latLng = { lat, lng };
              this.latitude = lat;
              this.longitude = lng;
              console.log(lat,lng,"co-or")
              this.center = {
                lat,
                lng,
              };
              this.markerPosition = {
                lat,
                lng,
              };
              this.editAddressForm.patchValue({
                primary_address: data.result.primary_address,
                secondary_address: data.result.secondary_address,
                lift: data.result.lift,
                floor: data.result.floor,
                landmark: data.result.landmark,
              });
              
             
            },
            error: (err) => console.log(err),
          });
        }
      },
    });
  }

  public openInfoWindow(marker: MapMarker, infoWindow: MapInfoWindow) {
    infoWindow.open(marker);
  }

  markerDragEnd(e) {
    var lat = e.latLng.lat();

    var lng = e.latLng.lng();

    this.latLng = {
      lat: lat,

      lng: lng,
    };
    var coordinates = [lng, lat];
    
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {
        location: this.latLng,
      },
      (results, status) => {
        console.log(results[0].formatted_address)
        this.searchAddress = results[0].formatted_address;
        
        this._UserBackupService.addressOnMarkerChange(this.user_Id,lat,lng,results[0].formatted_address).subscribe({
          next:(data)=>{
            console.log(data)
            if(data.result.delivery_area_match){
              this.editAddressForm.patchValue({
                primary_address: results[0].formatted_address,
              });
            }
            
          },
          error:(err)=> {
              console.log(err.error)
          },
        })
        if (results[0] === undefined || results[0] === null) {
          return;
        }
      }
    );
    
   
    this.latitude = lat;
    this.longitude = lng;
    this.center = {
      lat: this.latitude,
      lng: this.longitude,
    };
    this.markerPosition = {
      lat: this.latitude,
      lng: this.longitude,
    };
    
  }

  searchDataRecieved(data) {
    this.searchAddress = data.searchAddr;
    console.log( data.coordinates)
    this.latLng = data.coordinates;
    this.latitude = data.coordinates.lat;
    this.longitude = data.coordinates.lng;
    this.center = {
      lat: this.latitude,
      lng: this.longitude,
    };
    this.markerPosition = {
      lat: this.latitude,
      lng: this.longitude,
    };

    this.editAddressForm.patchValue({
      primary_address: data.searchAddr,
    });
    this._UserBackupService.addressOnMarkerChange(this.user_Id,data.coordinates.lat,data.coordinates.lng,data.searchAddr).subscribe({
      next:(resData)=>{
        console.log(resData)
        if(resData.result.delivery_area_match){
          this.editAddressForm.patchValue({
            primary_address: data.searchAddr,
          });
        }
      },
      error:(err)=> {
        console.log(err.error)
    },
    })
  }

  onNext() {
    this.loader=true
    const primaryAddress = this.editAddressForm.get("primary_address")?.value;
    const secondaryAddress =
      this.editAddressForm.get("secondary_address")?.value;
    const lift = this.editAddressForm.get("lift")?.value;
    const floor = this.editAddressForm.get("floor")?.value;
    const landmark = this.editAddressForm.get("landmark")?.value;

    this._UserBackupService
      .editUserAddress(
        this.user_Id,
        primaryAddress,
        secondaryAddress,
        lift,
        floor,
        this.longitude,
        this.latitude,
        landmark,
        this.delivery_note
      )
      .subscribe({
        next: (data) => {
          console.log(data);
          // this.router.navigate(['customer-mobile'])
          this.loader=false
          this.errorMessage=null
        },
        error: (error) => {
          console.error("Error:", error);
          this.loader=false
          this.errorMessage="Unable to update address"
        },
      });
  }
  selectLiftStatus(status: string) {
    if(status==='Yes'){
      this.editAddressForm.patchValue({ lift: true });
    }
    else {
      this.editAddressForm.patchValue({ lift: false });
    }
    
  }
}
