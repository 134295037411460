import {
    Injectable
  } from '@angular/core';
  import {
    HttpClient, 
    HttpHeaders
  } from '@angular/common/http';
  import {
    map
  } from 'rxjs/operators';
  import {
    AppConstants
  } from '../entities/appconstants';
  import {
    GlobalContextService
  } from './globalstorage.service';
  
  @Injectable({
    providedIn: 'root'
  })
  export class GlobalHttpService {
  
    headers = new HttpHeaders();
    constructor(
      private http: HttpClient,
      private _globalContextService: GlobalContextService) {}
    setTokenHeaders(customHeaders, isAppJsonHeaderRequired = true) {
  
      var objHeaders = {};
      if (isAppJsonHeaderRequired) {
        objHeaders["Content-Type"] = "application/json";
      }
      if (this._globalContextService.globalContext.userData != undefined &&
        this._globalContextService.globalContext.userToken != undefined)
        objHeaders[AppConstants.apiAccessKey] = this._globalContextService.globalContext.userToken;
  
      if (customHeaders != undefined && customHeaders != null) {
        for (let i = 0; i < customHeaders.length; i++) {
          var key = Object.keys(customHeaders[i])[0];
          objHeaders[key] = customHeaders[i][key];
        }
      }
      this.headers = new HttpHeaders(objHeaders);
    }
  
  
  
    /* All Http Methods  */
    getDataFromService(apiUrl: string, isLocal: boolean = false, customHeaders: any = null) {
     

      this.setTokenHeaders(customHeaders);
      if (!isLocal)
        apiUrl = this._globalContextService.globalContext.configurationdata.baseapiUrl + apiUrl;
      try {
        return this.http.get(apiUrl, {
          headers: this.headers
        });
      } catch (ex) {
        return ex;
      }
    }
  
    postDataToService(apiUrl: string, requestBody: any = null, customHeaders: any = null, isAppJsonHeaderRequired: boolean = true) {
      console.log("inside @postDataToService before call:", apiUrl);
      this.setTokenHeaders(customHeaders, isAppJsonHeaderRequired);
      apiUrl = this._globalContextService.globalContext.configurationdata.baseapiUrl + apiUrl;
      try {
        return this.http.post(apiUrl, requestBody, {
          headers: customHeaders
        });
      } catch (ex) {
        return ex;
      }
    } 

    postDataToServiceFixedApi(url, requestBody: any = null, customHeaders: any = null, isAppJsonHeaderRequired: boolean = true) {
      this.setTokenHeaders(customHeaders, isAppJsonHeaderRequired);
      try { 
        return this.http.post(url, requestBody, {
          headers: customHeaders
        });
      } catch (ex) {
        return ex;
      }
    } 
  
  
    putDataToService(apiUrl: string, requestBody: any, customHeaders: any = null) {
  
      this.setTokenHeaders(customHeaders);
      apiUrl = this._globalContextService.globalContext.configurationdata.baseapiUrl + apiUrl;
      try {
        return this.http.put(apiUrl, requestBody, {
          headers: this.headers
        });
      } catch (ex) {
        return ex;
      }
    }
  
  
    deleteDataFromService(apiUrl: string, isLocal: boolean = false, customHeaders: any = null) {
      this.setTokenHeaders(customHeaders);
  
      if (!isLocal)
        apiUrl = this._globalContextService.globalContext.configurationdata.baseapiUrl + apiUrl;
      try {
        //console.log(apiUrl);
        return this.http.delete(apiUrl, {
          headers: this.headers
        });
      } catch (ex) {
        return ex;
      }
    }
  
  }
  