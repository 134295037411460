import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


// import { Ng2SmartTableModule } from 'ng2-smart-table';
import { UsersRoutingModule } from './users-routing.module';
import { ListUserComponent } from './list-user/list-user.component';
import { CreateUserComponent } from './create-user/create-user.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { EditUserAddressComponent } from './edit-user-address/edit-user-address.component';
import { PlaceInitialOrderComponent } from './place-initial-order/place-initial-order.component';

@NgModule({
  declarations: [ListUserComponent, 
    EditUserAddressComponent,
    PlaceInitialOrderComponent,
    CreateUserComponent],
  imports: [
    CommonModule,
    NgbModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    UsersRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
  ]
})
export class UsersModule { }
