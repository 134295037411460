<div class="container-fluid">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <h5 class="float-start"><b>Order Total :</b>&#8377; 550/-</h5>
                    <p class="float-end">12/07/2024</p>
                </div>
                <div class="col-12">
                    <p>Bringjal X 2</p>
                </div>
                <div class="col-12">
                    <p>Deposite amount:&#8377; 250/-</p>
                </div>
                <div class="col-12">
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <button class="btn btn-outline-secondary btn-sm me-md-2" type="button">View Details</button>
                      </div>
                </div>
            </div>
        </div>
    </div>
</div>